import React, {Fragment, useEffect} from 'react';
import { useSelector, connect } from 'react-redux';
import config from '../../config';
import { escondeMensagem } from '../../features/aplicacao/aplicacaoSlice';

const mapDispatchToProps = { escondeMensagem };

function Mensagem (props) {
    
    const mensagem = useSelector((state) => state.aplicacaoStore.mensagem);

    useEffect(
        () => {
          if(!mensagem || !mensagem.tipo)  {
              return null;
          }

          window.scrollTo(0,0);
          let timer1 = setTimeout(() => props.escondeMensagem(), 1000 * config.potalLogadotempoFecharMensagensSegundos);
          return () => {
            clearTimeout(timer1);
          };
        }
    );
        
    
    const {tipo, titulo, texto} = mensagem;
    let estiloDiv;
    let estiloIcone;

    if(tipo === 'SUCESSO'){
        estiloDiv = "br-message success";
        estiloIcone = "fas fa-check-circle fa-lg";
    } else if(tipo === 'ERRO'){ 
        estiloDiv = "br-message danger";
        estiloIcone = "fas fa-times-circle fa-lg";
    } else if(tipo === 'INFORMACAO'){
        estiloDiv = "br-message info";
        estiloIcone = "fas fa-info-circle fa-lg";
    } else{
        estiloDiv = "br-message warning";
        estiloIcone = "fas fa-exclamation-triangle fa-lg";
    }

    return (
        <Fragment>
            { (mensagem && mensagem.tipo) &&
                <div className={estiloDiv} role="alert">
                    <div className="icon">
                        <i className={estiloIcone} aria-hidden="true"></i>
                    </div>
                    <div className="content">
                        <span className="message-title">{titulo}</span>
                        <span className="message-body"> {texto}</span>
                    </div>
                    <div className="close">
                    <button className="br-button circle small" type="button" aria-label="Fechar" onClick={() => props.escondeMensagem()}>
                        <i className="fas fa-times" aria-hidden="true"></i>
                    </button>
                    </div>
                </div>
            }
        </Fragment>
    )

}

export default connect(null,(mapDispatchToProps))(Mensagem);
