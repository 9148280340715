import styled from "styled-components";

export const Container = styled.div`
  > label {
    display: block;
  }

  > .labels {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 8px;
    user-select: none;

    > .br-tag {
      height: 32px;

      > label {
        margin-bottom: 0;
      }

      > input:focus + label {
        outline: 0;
      }
    }
  }
`;
