import { useCallback, useRef } from "react";
import { useEffect, createRef } from "react";
import flatpickr from "flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt";
import { BrButton, Icon } from "@govbr-ds/react-components";
import { useImperativeHandle } from "react";
import { forwardRef } from "react";
import styles from "./styles.module.css";
import classNames from "classnames";

export const DateTimePicker = forwardRef((props, ref) => {
  const refIcone = useRef();
  const {
    id = Math.random().toString(32).substring(2),
    label,
    dateProps,
    type = "date",
    mode = "single",
    editable,
    onClose,
    defaultDate,
    className,
  } = props;

  const options = {
    inputType: "text",
    outputType: "dateArray",
    placeholder: "dd/mm/aaaa",
    icon: "calendar-alt",
    dateFormat: "d/m/Y",
    ariaDateFormat: "j de F de Y",
    defaultDate,
    ...dateProps,
  };

  const pickerRef = createRef();

  const handleClose = useCallback(
    (date, string) => {
      onClose({
        string,
        date: date[0],
      });
    },
    [onClose]
  );

  useEffect(() => {
    const fpList = [];

    if (pickerRef.current) {
      const fp = flatpickr(pickerRef.current, {
        allowInput: editable,
        locale: Portuguese,
        time_24hr: true,
        mode,
        type,
        onClose: handleClose,
        disableMobile: true,
        ...options,
      });

      fpList.push(fp);
    }

    return () => {
      fpList.forEach((el) => el.destroy());
    };
  }, []);

  // Expose a clear method
  useImperativeHandle(ref, () => ({
    clear: () => {
      if (pickerRef.current) {
        pickerRef.current.value = "";
        const event = new Event("input", { bubbles: true });
        pickerRef.current.dispatchEvent(event);
      }
    },
    shake: () => {
      if (pickerRef.current) {
        pickerRef.current.classList.add(styles.shake);

        setTimeout(() => {
          pickerRef.current.classList.remove(styles.shake);
        }, 300);

        const event = new Event("input", { bubbles: true });
        pickerRef.current.dispatchEvent(event);
      }

      if (refIcone.current) {
        refIcone.current.classList.add(styles.shake);

        setTimeout(() => {
          refIcone.current.classList.remove(styles.shake);
        }, 300);
      }
    },
  }));

  return (
    <div className={classNames("br-datetimepicker", className)}>
      <div className="br-input has-icon">
        <label htmlFor={`${id}-input`}>{label}</label>
        <input
          id={`${id}-input`}
          type="text"
          placeholder="dd/mm/aaaa"
          data-input="data-input"
          ref={pickerRef}
          value={defaultDate}
          onChange={onClose}
        />
        <BrButton
          id={`${id}-button`}
          circle
          size="small"
          aria-label="Abrir Datepicker"
          onClick={() => pickerRef.current?.click()}
          ref={refIcone}
        >
          <Icon icon={"fas fa-calendar-alt"} />
        </BrButton>
      </div>
    </div>
  );
});
