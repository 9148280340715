import styled from 'styled-components';

export const DivCard = styled.div `
    cursor: pointer;
    min-height: 310px;
    margin-bottom: 30px;
    margin-left: 20px;
    margin-right: 20px;
   // min-width: 360px;      
`;

export const DivCartao = styled.div `
    background-size: 100% 100%;
    border-radius: 10px;    
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.16);   
    //border: 1px solid rgba(0, 0, 0, 0.16);           
`;

export const DivCartaoSuperior = styled.div `
    background-image: url(${props => props.imagemDocumento});    
    aspect-ratio: 2.6;  
    background-repeat: no-repeat;
    background-size: 100% 120%;
    padding: 5px 0 0 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
`;

export const DivCartaoInferior = styled.div `
    aspect-ratio: 6;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 5px 0 0 15px;
    font-size: 12px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
`;

export const TituloDocumento = styled.p `
    color: ${props => props.corFonte ? props.corFonte : '' };
    font-weight: bold;
    margin-top: 10px;
    font-size: 12px;    
`;

export const TextoNomeCidadao = styled.p `
    font-size: 12px;
    color: ${props => props.corFonte ? props.corFonte : '#000000'};
    margin-top: 22% !important;    
`;

export const TextoLabelIdentificacao = styled.p `
    font-size: 10px;
    color: #000000;
    margin-top: 5px;
    margin-bottom: 0;
`;

export const TextoNumeroIdentificacao = styled.p `
    font-size: 12px;
    color: #000000;
`;

export const TextoLabelSituacao = styled.p `
    font-size: 10px;
    color: #000000;
    margin-top: 5px;
    margin-bottom: 0;
`;

export const TextoSituacao = styled.p `
    font-size: 12px;
    color: #000000;
`;

export const TextoNomeEmissorDocumento = styled.p `
    font-size: 13px;
    color: #888888;
    text-transform: uppercase;
    margin-top: 15px;
    margin-bottom: 0;
`;

export const TextoNomeDocumento = styled.p `
    font-size: 20px;
    color: var(--interactive);
    margin-top: 13px;
`;

export const EstiloContainerDocumento = styled.p ` 
  color: var(--color);
  min-width: 340px;
  max-width: 360px;  
`;
