import styled from 'styled-components';

export const DivTituloMensagem = styled.div `
    font-size: 18px;
    font-weight: bold;
    color: #0151b9;
    margin-top: 12px;
    max-height: calc(var(--font-size-scale-up-01)*6);
`;

export const DivCorpoMensagem = styled.div `
    font-size: 18px;
    *>p  {
        color: #7198d2;
    }
`;

export const DivImagem = styled.div `
    position: absolute;
    text-align: center;
    width: 95%;
    margin-top: -50px;
`;

export const ImagemIcone = styled.img `
    content:url(${props => props.imagem});
    height:70px;
    width:70px;
    background: #ffffff;
    border-radius: 50px;
}
`;



