import { useCallback, useEffect, useRef } from "react";
import { Container } from "./styles";
import React from "react";
import { Close } from "../Close";

export function Conteudo({
  children,
  aberto,
  fecharPopover,
  seletoresNaoFecharAoClicar,
  ...props
}) {
  const refConteudo = useRef(null);

  const fecharPopoverQuandoClicarFora = useCallback(
    (event) => {
      if (refConteudo.current && !refConteudo.current.contains(event.target)) {
        if (seletoresNaoFecharAoClicar) {
          const containersNaoFecharQuandoClicar = [];

          for (let seletor of seletoresNaoFecharAoClicar) {
            const resultado = document.querySelectorAll(seletor);

            if (resultado instanceof NodeList) {
              containersNaoFecharQuandoClicar.push(...resultado);
            }
          }

          if (
            containersNaoFecharQuandoClicar.every(
              (container) => !container.contains(event.target)
            )
          ) {
            fecharPopover();
          }
        } else {
          fecharPopover();
        }
      }
    },
    [refConteudo, fecharPopover]
  );

  useEffect(() => {
    if (aberto) {
      document.addEventListener("click", fecharPopoverQuandoClicarFora);
    } else {
      document.removeEventListener("click", fecharPopoverQuandoClicarFora);
    }

    return () => {
      document.removeEventListener("click", fecharPopoverQuandoClicarFora);
    };
  }, []);

  const handlePressionarEscComPopoverAberto = useCallback(
    (evento) => {
      if (evento.key === "Escape") {
        fecharPopover();
      }
    },
    [fecharPopover]
  );

  useEffect(() => {
    if (aberto) {
      document.addEventListener("keydown", handlePressionarEscComPopoverAberto);
    } else {
      document.removeEventListener(
        "keydown",
        handlePressionarEscComPopoverAberto
      );
    }

    return () => {
      document.removeEventListener(
        "keydown",
        handlePressionarEscComPopoverAberto
      );
    };
  }, [aberto, handlePressionarEscComPopoverAberto]);

  const componentFechar = React.Children.toArray(children).find(
    (child) => child.type === Close
  );
  const listaOutrosComponentes = React.Children.toArray(children).filter(
    (child) => child.type !== Close
  );

  return (
    <Container
      className="shadow-lg bg-pure-0 rounder-md"
      ref={refConteudo}
      {...props}
    >
      <Close onClick={fecharPopover} />

      {listaOutrosComponentes &&
        listaOutrosComponentes.map((child) =>
          React.cloneElement(child, {
            fecharPopover,
          })
        )}
    </Container>
  );
}
