import BRSelect from "@govbr/dsgov/dist/components/select/select";

export class ExtendedBRSelect extends BRSelect {
  constructor(name, component) {
    super(name, component);
  }

  _closeSelect() {
    super._closeSelect();

    // Remove "Item não encontrado" da lista
    this._removeNotFoundElement();
  }
}
