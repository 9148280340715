import React, {Fragment} from 'react';
import { useSelector} from 'react-redux';
import CardDocumento from '../CardDocumento';
import {EstiloContainerDocumento} from '../CardDocumento/styles'
import { cpfComMascara } from '../../../scripts/textUtil';

export default function CarteiraOuroPrata (props) {
    const usuario = useSelector((state) => state.usuarioStore.usuario);
   // const estiloContainerDocumento = 'col-xs-12 col-sm-10 col-md-6 col-lg-4 col-xl-3';
    const documentos = props.documentos;
    
   // console.log("passei ouro")
    return (
        <Fragment>
            <div className='cabecalho_painel'>
                <p>Visualize abaixo os documentos digitais que você adicionou à sua Carteira por meio do <b>aplicativo gov.br</b>.</p>
                <p>Para visualizar outros documentos, acesse o aplicativo, selecione "Carteira de documentos" e adicione os documentos que precisar.</p>
                <p>Pelo aplicativo gov.br, também é possível favoritar, compartilhar ou excluir seus documentos.</p>
            </div>


            {documentos && 
                <div className="row">
                    {documentos.map((documento, index) => {
                            const numeroIdentificacao = documento.tipoDoc === 'CPF' ? cpfComMascara(documento.card.identificacao.valor) : documento.card.identificacao.valor;
                            const labelSituacao = documento.tipoDoc === 'CPF' || documento.tipoDoc === 'CDI' ? 'Situação' : '';
                            const situacao = documento.tipoDoc === 'CPF' ? documento.situacao : documento.tipoDoc === 'CDI' ? documento.infoCdi.situacaoDescricao : '';
                                                    
                             return (
                                
                                <EstiloContainerDocumento key={index}>
                                    <CardDocumento imagemDocumento={`data:image/png;base64,${documento.card.background}`} 
                                        tituloDocumento={documento.card.titulo.toUpperCase()}
                                        numeroIdentificacao={numeroIdentificacao}
                                        labelIdentificacao={documento.card.identificacao.label}
                                        labelSituacao={labelSituacao}
                                        situacao={situacao}
                                        nomeDocumento={documento.descricao} 
                                        nomeOrgao={documento.orgao}
                                        corFonte={documento.tipoDoc !== 'CPF' ? '#000000' : '#ffffff'}
                                        onClick={() => props.onSelecionaDocumento(documento)}/>
                                </EstiloContainerDocumento>
                            )
                        })
                    }
                </div>
            }
            
        </Fragment>
    )
}