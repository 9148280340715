import styled from 'styled-components';

export const TextoData = styled.p `
    font-size: 12px;
    color: #707070;
    margin-top: 5px;
    margin-bottom: 0;
    text-transform: uppercase;
`;

export const TextoOrgao = styled.p `
    font-size: 18px;
    color: #888888;
    margin-top: 5px;
    margin-bottom: 0;
`;

export const TextoAssunto = styled.p `
    font-size: 28px;
    color: #1351B4;
    margin-top: 5px;
    margin-bottom: 0;
    font-weight: bold;
`;

export const TextoConteudo = styled.p `
    font-size: 22px;
    color: #333333;
    margin-top: 5px;
    margin-bottom: 0;
`;