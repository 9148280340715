import React, { useCallback, useState } from "react";
import { Trigger } from "../Trigger";
import { Conteudo } from "../Conteudo";
import { Container } from "./styles";
import { Overlay } from "../overlay";

export function Root({ children }) {
  const [aberto, setAberto] = useState(false);

  const toggleAberto = useCallback(
    () => setAberto((estadoAnterior) => !estadoAnterior),
    []
  );

  const fecharPopover = useCallback(() => setAberto(false), []);

  return (
    <Container>
      {React.Children.map(children, (child) => {
        if (child.type === Trigger) {
          return React.cloneElement(child, {
            aberto,
            onClick: toggleAberto,
            fecharPopover,
          });
        } else if (child.type === Conteudo) {
          if (!aberto) return <></>;

          return React.cloneElement(child, {
            aberto,
            fecharPopover,
            "aria-label": aberto,
            "aria-expanded": aberto,
            "data-aberto": aberto ? "aberto" : "fechado",
            "aria-controls": "popover-id",
            "aria-haspopup": "dialog",
          });
        }
      })}
      {aberto && <Overlay onClick={fecharPopover} />}
    </Container>
  );
}
