import React from "react";

function Linha({ width, height, className }) {
  return (
    <span
      className={`skeleton line d-block ${className}`}
      style={{
        width,
        height,
      }}
    ></span>
  );
}

export default Linha;
