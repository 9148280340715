import styled from 'styled-components';

export const TextoData = styled.p `
    font-size: 12px;
    color: #888888;
    margin-top: 5px;
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: ${props => props.negrito ? 'bold' : ''};
`;

export const TextoOrgao = styled.p `
    font-size: 13px;
    color: #1351B4;
    margin-top: 5px;
    margin-bottom: 0;
    font-weight: ${props => props.negrito ? 'bold' : ''};
`;

export const TextoAssunto = styled.p `
    font-size: 12px;
    color: #000000;
    margin-top: 5px;
    margin-bottom: 0;
    font-weight: ${props => props.negrito ? 'bold' : ''};
`;

export const TDClicavel = styled.td `
    cursor: pointer;
`