import React, {Fragment} from 'react';
import { useSelector } from 'react-redux';

function NomeUsuario (props) {

    const formataNome = (nome) => {
        if(! nome) {
            return nome;
        }

        return nome.toUpperCase()
    }
    

    const usuario = useSelector((state) => state.usuarioStore.usuario);
    let nomeUsuario;
    if(props.nomeCompleto) {
        nomeUsuario = usuario.nome;
    } else {
        nomeUsuario = usuario.primeiroNome;
    }
    nomeUsuario = formataNome(nomeUsuario);

    return (
        <Fragment>
            {nomeUsuario}
        </Fragment>
    )
}

export default NomeUsuario;