import styled from 'styled-components';

export const Imagem = styled.img `
    border-radius:50%;
    overflow:hidden;
    width:70px;
    height:70px;
    min-width: 70px;
    min-heigth:70px;
    margin-right: 15px;
    border:3px solid #ccc;
`;

export const TextoCumprimento = styled.div `
    color: #636363;
    font-size: 1.2rem;
    display: inline !important;
`;

export const DivBotaoAumentarNivelConta = styled.div `
    margin: 0;
    font-family: 'rawline', Rawline, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #333333;
    text-align: left;
    background-color: #fff;
`;




