import React, {Fragment, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { exibeMensagemInformativa, exibeMensagemErro } from '../../../features/aplicacao/aplicacaoSlice';
import api from '../../../services/api';
import config from '../../../config';
import { Link } from 'react-router-dom';

const mapDispatchToProps = { exibeMensagemInformativa, exibeMensagemErro};

function AtualizaNotificacoes (props) {

  const [ifModifiedSince, setIfModifiedSince] = useState(new Date()); 

    useEffect(() => {
        const interval = setInterval(() => {
          verificaNovasNotificacoes();
        }, config.portalLogadoTempoVerificarNovasNotificacoes * 1000);
        
        return () => clearInterval(interval); 
      }, [ifModifiedSince])

     const verificaNovasNotificacoes = () => {

        api.get('/api/mensagem/verificar-novas', { headers: { 'If-Modified-Since': ifModifiedSince.toUTCString() } })
        .then(response => { 
            const retorno = response.data;
            if(retorno.new_message === true){
              props.exibeMensagemInformativa({titulo: 'Você recebeu uma nova notificação:', texto:<Link to="/notificacoes">Ir para a caixa de entrada</Link>});
            }
        })
        .catch(erro => {
            console.log("Não foi possível chamar o serviço corretamente", erro);
            props.exibeMensagemErro({titulo: 'Erro durante o atualização automática notificações:', texto:'Não foi possível atualizar as notificações'});
        })

        setIfModifiedSince(new Date());
     }

     return (
        <Fragment>
        </Fragment>
     )
}

export default connect(null,(mapDispatchToProps))(AtualizaNotificacoes);