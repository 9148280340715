import React from "react";
import LabelTab from "../LabelTab";
import CardSkeleton from "./CardSkeleton";
import { Container } from "../ListaServicos/styles";

function HistoricoSkeleton() {
  return (
    <section className="mt-5">
      <div className="br-tab small">
        <nav
          className="tab-nav"
          style={{
            scrollbarWidth: "none",
            borderBottom: "1px solid var(--border-color)",
          }}
        >
          <ul className="border-solid-none">
            <li className="tab-item active" title="Todos">
              <LabelTab label="Todos" data-panel="panel-1" />
            </li>

            <li className="tab-item">
              <LabelTab label="Em andamento" data-panel="panel-2" />
            </li>

            <li className="tab-item">
              <LabelTab label="Concluído" data-panel="panel-3" />
            </li>
          </ul>
        </nav>

        <div className="tab-content">
          <div className="tab-panel active" id="panel-1">
            <TabContent />
          </div>

          <div className="tab-panel" id="panel-2">
            <TabContent />
          </div>

          <div className="tab-panel" id="panel-3">
            <TabContent />
          </div>
        </div>
      </div>
    </section>
  );
}

function TabContent() {
  return (
    <Container>
      {Array.from({ length: 3 }).map(() => (
        <CardSkeleton key={Math.random().toString(32)} />
      ))}
    </Container>
  );
}

export default HistoricoSkeleton;
