import { useSelector } from "react-redux";

export default function Avatar() {
  const usuario = useSelector((state) => state.usuarioStore.usuario);
  const foto = useSelector((state) => state.usuarioStore.photo);

  return (
    <>
      <span className="br-avatar" title={usuario.nome}>
        <span className="image" style={{ border: "2px solid #ccc" }}>
          <img src={foto} alt="avatar" style={{ objectFit: "cover" }} />
        </span>
      </span>
    </>
  );
}
