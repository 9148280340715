import React, {Fragment, useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from "react-router-dom";
import HeaderPagina from '../../HeaderPagina';
import ModalConfirmacao from '../../ModalConfirmacao';
//import IconeFavorito from '../IconeFavorito';
import { formataDataHoraInvertidaComTimeZone, diaSemanaDataHoraInvertidaComTimeZone } from '../../../scripts/dataUtil';
import { TextoData, TextoOrgao, TextoAssunto, TextoConteudo } from './styles';
import api from '../../../services/api';
import { exibeMensagemSucesso, exibeMensagemErro, escondeMensagem, exibeEmProcessamento, escondeEmProcessamento } from '../../../features/aplicacao/aplicacaoSlice';
import Header from '../../Header';
const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, escondeMensagem, exibeEmProcessamento, escondeEmProcessamento };

function DetalhamentoNoficacao (props) {

    const caminhoBreadcrumb = [{texto: 'Notificações', link:'/notificacoes'}, {texto: 'Detalhamento da mensagem'}];
    const location = useLocation();
    const notificacao = location.state.notificacao;
    const [exibeConfirmacaoExclusao, setExibeConfirmacaoExclusao] = useState(false);
    const history = useHistory();

    useEffect(() => {
        props.escondeMensagem();
        if(notificacao && notificacao.read === false) {
            props.exibeEmProcessamento({texto: 'Atualizando mensagem'});
            api.put(`/api/mensagem/${notificacao.id}/`)
            .then(response => { 
                                
            })
            .catch(erro => {
                console.log("Não foi possível chamar o serviço corretamente", erro);
                props.exibeMensagemErro({titulo: 'Erro durante o carregamento da página:', texto:'Não foi possível atualizar a situação da mensagem'});
            })
            .finally(() => {
                props.escondeEmProcessamento();
            });
        }
    }, []);

    const excluirNotificacao = () => {
        props.exibeEmProcessamento({texto: 'Excluindo mensagem'});
        api.delete(`/api/mensagem/${notificacao.id}`)
        .then(response => { 
            props.exibeMensagemSucesso({texto:'Notificação excluída com sucesso!'})
            history.push('/notificacoes');
        })
        .catch(erro => {
            console.log("Não foi possível chamar o serviço corretamente", erro);
            props.exibeMensagemErro({titulo: 'Erro durante a exclusão da notificação:', texto:'Não foi possível excluir a notificação'});
        })
        .finally(() => {
            props.escondeEmProcessamento();
        });
    }

     return (
        <Fragment>
             <Header/>
            <div className="container-lg">
                <ModalConfirmacao visivel={exibeConfirmacaoExclusao} 
                    titulo='Exclusão de aviso' 
                    corpo='Confirma a exclusão do aviso?'
                    confirmarLabel = 'Excluir'
                    onConfirmar={() => {
                            excluirNotificacao();                    
                        }
                    }
                    cancelarLabel = 'Cancelar'
                    onCancelar={()=> setExibeConfirmacaoExclusao(false)}
                />
                <HeaderPagina caminhoBreadcrumb={caminhoBreadcrumb} titulo=''/>

                <div className="d-flex align-items-center mt-0">
                    <div className="m-half">
                        <button className="br-button circle" type="button" aria-label="Ícone ilustrativo" onClick={() => history.push('/notificacoes')}>
                            <i className='fas fa-chevron-left' aria-hidden='true'></i>
                        </button>
                    </div>
                    {/*}
                    <div className="m-half">
                        <button className="br-button circle" type="button" aria-label="Ícone ilustrativo" onClick={() => alert('Funcionalidade ainda não implementada!')}>
                            <IconeFavorito favorito={notificacao.favorite}/>
                        </button>
                    </div>
                    {*/}
                    <div className="m-half">
                        <button className="br-button circle" type="button" aria-label="Ícone ilustrativo" onClick={() => setExibeConfirmacaoExclusao(true)}>
                            <i className="fa fa-trash" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>

                <span className="br-divider my-0"/>
                
                <TextoOrgao negrito={!notificacao.read}>{notificacao.from}</TextoOrgao>
                <TextoAssunto negrito={!notificacao.read}>{notificacao.title}</TextoAssunto>
                <hr/>
                <TextoData>
                    {diaSemanaDataHoraInvertidaComTimeZone(notificacao.created_at)}&nbsp;
                    {formataDataHoraInvertidaComTimeZone(notificacao.created_at)}
                </TextoData>
                <hr/>
                <TextoConteudo>{notificacao.content}</TextoConteudo>
            </div>
        </Fragment>
    )
}

export default connect(null,(mapDispatchToProps))(DetalhamentoNoficacao);