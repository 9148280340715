import styled from "styled-components";

export const Container = styled.button`
  display: flex;
  align-items: center;
  gap: var(--spacing-scale-base);
  justify-content: space-between;
  white-space: nowrap;
  padding: var(--spacing-scale-half) var(--spacing-scale-baseh);
  position: relative;
  overflow: visible;

  > i {
    transition: transform 150ms;
  }

  &[data-aberto="true"] {
    > i {
      transform: rotate(180deg);
    }
  }

  @media (max-width: 370px) {
    > span,
    i {
      font-size: var(--font-size-scale-base);
    }
  }
`;

export const Conteudo = styled.div`
  display: ${(props) => (props.aberto ? "block" : "none")};
  position: absolute;
  top: calc(100% + 6px);
  right: 0;
  z-index: 100;
  width: 200px;
  > ul {
    padding: var(--spacing-scale-base);
    margin: 0;
    list-style: none;
    text-align: left;
  }
`;

export const OpcaoOrdenacao = styled.li`
  border-radius: var(--surface-rounder-sm);
  padding: 4px 24px 4px 8px;

  &[aria-selected="true"] {
    background-color: var(--gray-5) !important;
  }

  > label {
    margin: 0;
  }
`;
