import React, { Fragment } from "react";
import { TextoNivelConta, ImagemNivelConta, Container } from "./styles";
import { useSelector } from "react-redux";
import ouro from "../../images/barra-ouro.svg";
import prata from "../../images/barra-prata.svg";
import bronze from "../../images/barra-bronze.svg";

export default function NivelConta(props) {
  const usuario = useSelector((state) => state.usuarioStore.usuario);
  const imagemNivel = usuario.isOuro ? ouro : usuario.isPrata ? prata : bronze;
  const alt = usuario.isOuro
    ? "Imagem de conta nível ouro"
    : usuario.isPrata
    ? "Imagem de conta nível prata"
    : "Imagem de conta nível bronze";

  return (
    <Fragment>
      <Container
        className="row"
        clicavel={props.clicavel}
        onClick={props.onClick}
      >
        <div className="col-12">
          <TextoNivelConta>
            SUA CONTA GOV.BR É NÍVEL <b>{usuario.selo_conta}</b>
          </TextoNivelConta>
        </div>
        <div className="col-12">
          <ImagemNivelConta src={imagemNivel} alt={alt} />
        </div>
      </Container>
    </Fragment>
  );
}
