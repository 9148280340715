import React, {Fragment, useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import HeaderPagina from '../HeaderPagina';
import { TextoData, TextoOrgao, TextoAssunto, TDClicavel } from './styles';
import { formataDataHoraInvertidaComTimeZone, diaSemanaDataHoraInvertidaComTimeZone } from '../../scripts/dataUtil';
import PaginacaoTipo2 from '../PaginacaoTipo2';
import ModalConfirmacao from '../ModalConfirmacao';
import { exibeMensagemSucesso, exibeMensagemErro, exibeEmProcessamento, escondeEmProcessamento } from '../../features/aplicacao/aplicacaoSlice';
import api from "../../services/api";
import Header from '../Header';
//import IconeFavorito from './IconeFavorito';


const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, exibeEmProcessamento, escondeEmProcessamento };

function Notificacoes (props) {

    const caminhoBreadcrumb = [{texto: 'Notificações'}];
    const [notificacoes, setNotificacoes] = useState([]);
    const [totalPaginas, setTotalPaginas] = useState(0);
    const [paginaAtual, setPaginaAtual] = useState(0);
    const [totalRegistros, setTotalRegistros] = useState(0);
    const [pesquisaExecutada, setPesquisaExecutada] = useState(false);
    const [selecionaTodos, setSelecionaTodos] = useState(false);
    const [exibeConfirmacaoExclusao, setExibeConfirmacaoExclusao] = useState(false);
    const history = useHistory();

    useEffect(() => {
        atualizaListaNotificacoes(0);
     }, []);

     const atualizaListaNotificacoes = (pagina) => {
        props.exibeEmProcessamento({texto: 'Atualizando mensagens'});
        setSelecionaTodos(false);
        setPesquisaExecutada(false)

        api.get(`/api/mensagem?pagina=${pagina}`)
        .then(response => { 
            if(response.status === 200) {
                const retorno = response.data;
                const conteudo = retorno.content; 
                setNotificacoes(conteudo);
                setTotalPaginas(retorno.total_pages);
                setPaginaAtual(retorno.page); 
                setTotalRegistros(retorno.total_elements); 
                if(conteudo && conteudo.length > 0) {
                    setNotificacoes(conteudo.map(n => ({...n, selecionado: false})))
                }
            } else {
                setNotificacoes([]);
                setTotalPaginas(0);
                setPaginaAtual(0);
                setTotalRegistros(0);
            }
            setPesquisaExecutada(true);
        })
        .catch(erro => {
            console.log("Não foi possível chamar o serviço corretamente", erro);
            props.exibeMensagemErro({titulo: 'Erro durante o carregamento da lista de notificações:', texto:'Não foi possível carregar a lista de notificações'});
        })
        .finally(() => {
            props.escondeEmProcessamento();
        });
     }
     
     const selecionaNotificacao = (index) => {
        const notificacoesNova = [...notificacoes];
        notificacoesNova[index].selecionado = !notificacoesNova[index].selecionado;
        setNotificacoes(notificacoesNova);
        setSelecionaTodos(false);
     }

     const selecionaTodasNotificacoes = () => {
         const notificacoesNova = [...notificacoes].map(n => { return {...n, selecionado: !selecionaTodos}});
         setNotificacoes(notificacoesNova);
         setSelecionaTodos(!selecionaTodos);
     }

     const existemItensSelecionados = () => {
         return notificacoes.filter(n => n.selecionado).length >0;
     }

     const excluirNotificacoes = () => {
        const ids = notificacoes.filter(n => n.selecionado).map(n => n.id);

        setExibeConfirmacaoExclusao(false);
        props.exibeEmProcessamento({texto: 'Excluindo mensagens'});
        api.post('/api/mensagem/deleta', {ids})
        .then(response => { 
            props.exibeMensagemSucesso({texto:'Notificações excluídas com sucesso!'})
            atualizaListaNotificacoes(0);
        })
        .catch(erro => {
            console.log("Não foi possível chamar o serviço corretamente", erro);
            props.exibeMensagemErro({titulo: 'Erro durante a exclusão das notificações:', texto:'Não foi possível excluir as notificações'});
        })
        .finally(() => {
            props.escondeEmProcessamento();
        });
    }

    return (
        <Fragment>
            <Header/>
            <div className="container-lg">
                <ModalConfirmacao visivel={exibeConfirmacaoExclusao} 
                    titulo='Exclusão de aviso(s)' 
                    corpo='Confirma a exclusão dos avisos selecionados?'
                    confirmarLabel = 'Excluir'
                    onConfirmar={() => {
                            excluirNotificacoes();                    
                        }
                    }
                    cancelarLabel = 'Cancelar'
                    onCancelar={()=> setExibeConfirmacaoExclusao(false)}
                />
                <HeaderPagina caminhoBreadcrumb={caminhoBreadcrumb} titulo='Notificações'/>
                
                <div className='cabecalho_painel'>
                    <p>As notificações exibidas abaixo também estão disponíveis no seu aplicativo gov.br.</p>
                    
                    <div className='br-table'>
                        <div className='table-header'>
                            <div className='top-bar' style={{paddingLeft:0}}>
                                <div className='table-title'>
                                    NOTIFICAÇÕES RECEBIDAS
                                    <span className="br-divider my-1"/>

                                    <div className="d-flex align-items-center">
                                        {notificacoes && totalRegistros > 0 &&
                                            <Fragment>
                                                <div className="mr-0 ml-0 pl-0 pr-0">
                                                    <div className="br-checkbox " onClick={() => selecionaTodasNotificacoes()}>
                                                        <input type="checkbox" checked={selecionaTodos === true} readOnly={true}/>
                                                        <label/>
                                                    </div>
                                                </div>
                                                {/*}
                                                <div className="mr-0 ml-0 pl-0 pr-0">
                                                    <button className="br-button circle" type="button" aria-label="Ícone ilustrativo" disabled={!existemItensSelecionados()} onClick={() => alert('Funcionalidade ainda não implementada!')}>
                                                        <i className="fa-regular fa-star" aria-hidden="true"/>
                                                    </button>
                                                </div>
                                                {*/}
                                            </Fragment>
                                        }
                                        <div className=" mr-0 ml-0 pl-0 pr-0">
                                            <button className="br-button circle" type="button" aria-label="Ícone ilustrativo" onClick={() => atualizaListaNotificacoes(0)}>
                                                <i className="fa fa-refresh" aria-hidden="true"/>
                                            </button>
                                        </div>
                                        {notificacoes && totalRegistros > 0 &&
                                            <div className="mr-0 ml-0 pl-0 pr-0">
                                                <button className="br-button circle" type="button" aria-label="Ícone ilustrativo" disabled={!existemItensSelecionados()} onClick={() => setExibeConfirmacaoExclusao(true)}>
                                                    <i className="fa fa-trash" aria-hidden="true"/>
                                                </button>
                                            </div>
                                        }
                                    </div>

                                    <span className="br-divider my-0"/>
                                </div>
                            </div>
                        </div>
                        {notificacoes && totalRegistros > 0 &&
                            <Fragment>
                                <table>
                                    <tbody>
                                        {notificacoes.map((notificacao, index) => 
                                            <Fragment key={index}>
                                                <tr>
                                                    <td style={{width: '1%'}} className='mr-0 ml-0 pl-1 pr-1'>
                                                        <div className="br-checkbox" onClick={() => selecionaNotificacao(index)}>
                                                            <input type="checkbox" checked={notificacao.selecionado === true} readOnly={true}/>
                                                            <label/>
                                                        </div>
                                                    </td>

                                                    {/*}
                                                    <td style={{width: '1%'}} className=' mr-0 ml-0 pl-1 pr-1'>
                                                        <IconeFavorito favorito={notificacao.favorite}/>
                                                    </td>
                                                    {*/}

                                                    <TDClicavel onClick={() => history.push('/detalhamentoNotificacao', {notificacao})} className=' mr-0 ml-0 pl-1 pr-1'>
                                                        <TextoData>
                                                            {diaSemanaDataHoraInvertidaComTimeZone(notificacao.created_at)},&nbsp;
                                                            {formataDataHoraInvertidaComTimeZone(notificacao.created_at)}
                                                        </TextoData>
                                                        <TextoOrgao negrito={!notificacao.read}>{notificacao.from}</TextoOrgao>
                                                        <TextoAssunto negrito={!notificacao.read}>{notificacao.title}</TextoAssunto>
                                                    </TDClicavel>

                                                    <TDClicavel style={{width: '3%'}} onClick={() => history.push('/detalhamentoNotificacao', {notificacao})}>
                                                        <i className='fas fa-chevron-right' aria-hidden='true'></i>
                                                    </TDClicavel>
                                                </tr>
                                            </Fragment>
                                        )}
                                    </tbody>
                                </table>
                            
                                <div className='table-footer'>
                                    <br/>
                                    <PaginacaoTipo2 
                                        totalPaginas={totalPaginas} 
                                        paginaAtual={paginaAtual} 
                                        onPageChange={atualizaListaNotificacoes}/>
                                </div>
                            </Fragment>
                        }

                    </div>
                    
                    {pesquisaExecutada && (!notificacoes || totalRegistros === 0) &&
                        <p>Não existem mensagens cadastradas</p>
                    }
                </div>
            </div>
        </Fragment>
    )
}

export default connect(null,(mapDispatchToProps))(Notificacoes);