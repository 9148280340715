import React from 'react';
import { useSelector } from 'react-redux';
import config from '../../config';

export default function BotaoAumentarNivelConta (props) {

    const usuario = useSelector((state) => state.usuarioStore.usuario);
    const estilo = `lvl-button ${props.destaque ? props.destaque: 'secondary'} mt-3 mt-sm-0 ml-sm-3`;
    return (
        <button className={estilo} type="button" aria-label="Aumentar nível da conta" onClick={() =>  window.location.assign(config.govbrConfiabilidadesUrl)}>
            <i className={usuario.isOuro ? 'fa-solid fa-eye mr-1' : 'fa-solid fa-arrow-trend-up mr-1'} aria-hidden="true"></i>
            {usuario.isOuro ? 'VER NÍVEL' : 'AUMENTAR NÍVEL' }
        </button>
    )

}