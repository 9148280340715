import React, {Fragment} from 'react';

export default function Modal (props) {
    const overflow = props.overflow ? props.overflow : 'auto';
    return (
        <Fragment>
                <div className={`br-scrim foco ${props.visivel ? 'active': ''}`}>
                    <div className={`br-modal ${props.classModal ? props.classModal : ''}`} style={{minWidth: '350px', overflow}}>
                        {props.children}
                    </div>
                </div>
        </Fragment>
    )
}