import styled from "styled-components";

export const Card = styled.li`
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    box-shadow: none;
    border-bottom: 1px solid var(--gray-30);
    margin-bottom: 0;
  }
`;

export const CardContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    grid-template-columns: repeat(7, 1fr) 136px;
  }
`;

export const Titulo = styled.h5`
  color: var(--blue-vivid-70);
  text-transform: none;
  margin: 0 0 var(--spacing-scale-base) 0;
  padding: 0;
  font-weight: var(--font-weight-bold);

  @media (max-width: 575px) {
    font-size: calc(
      (var(--font-size-scale-up-01) + var(--font-size-scale-up-02)) / 2
    );
  }

  @media (min-width: 768px) {
    grid-column: 1/8;
  }
`;

export const Detalhes = styled.div`
  @media (min-width: 575px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 768px) {
    grid-column: 1/8;
  }

  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-scale-baseh) var(--spacing-scale-3x);
  margin: var(--spacing-scale-2x) 0;

  > span {
    color: var(--gray-60);
    font-weight: var(--font-weight-semi-bold);
  }

  > span:nth-of-type(3) {
    grid-column: 1/2;
  }

  > span > strong {
    color: var(--gray-90);
    font-weight: var(--font-weight-semi-bold);
    display: inline-flex;
    align-items: baseline;
  }
`;

export const Situacao = styled.span`
  > strong > i {
    color: ${(props) =>
      props.situacaoServico === 2
        ? "var(--green-cool-vivid-40)"
        : "var(--gold-vivid-20)"};
  }
`;

export const BotaoVerResponder = styled.div`
  margin: 0 auto;
  padding: 0;
  margin-top: var(--spacing-scale-baseh);
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${(props) => {
    return props.temBotaoAvaliacao ? "space-between" : "center";
  }};

  > a {
    max-width: 140px;
    width: 100%;
  }

  @media (min-width: 768px) {
    display: flex;
    grid-column: 8/9;
    grid-row: 1/3;
    flex-direction: column;
    justify-content: center;
    gap: var(--spacing-scale-base);
    margin-top: 0;

    > a {
      width: 100%;
    }
  }
`;

export const Pendencia = styled.span`
  color: var(--red-cool-vivid-60);
  font-weight: var(--font-weight-semi-bold);
  position: absolute;
  left: 0;
  grid-column: 1/3;
  display: flex;
  align-items: center;

  > i {
    font-size: var(--icon-size-sm) !important;
  }

  @media (min-width: 768px) {
    position: relative;
    font-size: var(--font-size-scale-base) !important;

    > i {
      font-size: var(--icon-size-base) !important;
      margin-right: 4px;
    }
  }

  @media (max-width: 374px) {
    font-size: var(--font-size-scale-down-01);
  }
`;

export const Divisor = styled.hr`
  margin: 0;

  @media (min-width: 768px) {
    display: none;
  }
`;
