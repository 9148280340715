import { createSlice } from '@reduxjs/toolkit';
import jwt_decode from "jwt-decode";
import { localStorageLogin } from '../../scripts/localStoreUtil';

export const usuarioSlice = createSlice({
  name: 'usuario',
  initialState: {
    token: '',
    usuario: {},
    photo: '',
    chaveConsultaCertidaoCND : {},
  },
  reducers: {
    login: (state, action) => {
        const {token, foto} = action.payload;
        let user = {};
        if(token){
          user = jwt_decode(token);
        }
        if(user && user.nome) {
          if(user.nome) {
            user['primeiroNome'] = user.nome.split(' ')[0];
          }
          if(user.selo_conta) {
            user['selo_conta'] = user.selo_conta.toUpperCase();
          }
          user['isOuro'] = user['selo_conta'] === 'OURO';
          user['isPrata'] = user['selo_conta'] === 'PRATA';
          user['isBronze'] = user['selo_conta'] === 'BRONZE';              
        
          user['isCNPJ'] = user['cnpj'] === ''  ? false : true;          
        }
       
        state.usuario = user;
        state.token = token;       
        state.photo = foto;
        localStorageLogin(token, foto);
    },

    setChaveConsultaCertidaoCND: (state, action) => {
      const {chave} = action.payload;
      const chaveConsultaCertidaoCND = {};
      if(chave) {
        chaveConsultaCertidaoCND['chave'] = chave;
        chaveConsultaCertidaoCND['criacao'] =  Date.now();
      }
        state.chaveConsultaCertidaoCND = chaveConsultaCertidaoCND;
    },

  },
})

export const { login, setChaveConsultaCertidaoCND } = usuarioSlice.actions

export default usuarioSlice.reducer