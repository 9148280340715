import React, {Fragment} from 'react';
import { useHistory } from "react-router-dom";
import Breadcrumb from '../BreadCrumb';
import Mensagem from '../Mensagem';
import {TextoTitulo, BarraTitulo} from './styles';

export default function HeaderPagina (props) {

    const history = useHistory();
    const caminhoBreadcrumb = props.caminhoBreadcrumb;
    let linkPaginaAnterior = null;
    if (caminhoBreadcrumb && caminhoBreadcrumb.length > 0) {
        if(caminhoBreadcrumb.length === 1) {
            linkPaginaAnterior = '/';
        } else {
            linkPaginaAnterior = caminhoBreadcrumb[caminhoBreadcrumb.length -2].link;
        }
    }

    return (
        <Fragment>
            <Mensagem/>
            {caminhoBreadcrumb && caminhoBreadcrumb.length > 0 &&
                <Breadcrumb caminho={caminhoBreadcrumb}/>
            }
            {props.titulo &&
                <Fragment>
                    <div className="d-flex flew-row align-items-center">
                        {linkPaginaAnterior &&
                            <div className="mt-1">
                                <button className="br-button circle" type="button" aria-label="Ícone ilustrativo" onClick={() => history.push(linkPaginaAnterior)}>
                                    <i className='fas fa-chevron-left' aria-hidden='true'></i>
                                </button>
                            </div>
                        }

                        <TextoTitulo>{props.titulo}</TextoTitulo>
                    </div>
                    <BarraTitulo className="br-divider"></BarraTitulo>
                </Fragment>
            }
        </Fragment>
    )
}