import React, {Fragment} from 'react';
import { useSelector } from 'react-redux';
import BotaoAumentarNivelConta from '../BotaoAumentarNivelConta';

import NivelConta from '../NivelConta';
import PainelPrincipal from '../PainelPrincipal';
import Banner from '../Banner';
import { Imagem, TextoCumprimento, DivBotaoAumentarNivelConta } from './styles';
import HeaderPagina from '../HeaderPagina';
import NomeUsuario from '../NomeUsuario';
import ModalAssinarDocumentos from '../ModalAssinarDocumentos';
import ModalEmProcessamento from '../ModalEmProcessamento';
import AtualizaNotificacoes from '../Notificacoes/AtualizaNotificacoes';
import Header from '../Header';


export default function Home (props) {

    const foto = useSelector((state) => state.usuarioStore.photo);
    const usuario = useSelector((state) => state.usuarioStore.usuario);
    return (
        <Fragment>
            <Header/>
			<ModalAssinarDocumentos/>
			<ModalEmProcessamento/>
			<AtualizaNotificacoes/>

            <Banner/>
            <div className="container-lg">
                <HeaderPagina/>
                <div className="main-content mt-4" id="main-content">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-8 col-lg-7 col-xl-7">
                            <div className="d-flex mb-5 flex-row align-items-center">
                                <Imagem src={foto} alt="avatar"/>
                                <TextoCumprimento>Olá, {!usuario.isCNPJ && <strong><NomeUsuario nomeCompleto={true}/></strong>}</TextoCumprimento>
                            </div>
                        </div>            
                        <div className="col-12 col-sm-12 col-md-4 col-lg-5 col-xl-5">
                     
                        { !usuario.isCNPJ && <NivelConta/>}
                            { !usuario.isCNPJ && (
                            <DivBotaoAumentarNivelConta>
                                <BotaoAumentarNivelConta />
                            </DivBotaoAumentarNivelConta>
                        )}
                        </div>                   
                    </div>       
                    { !usuario.isCNPJ && (       
                      
                    <div className='cabecalho_home'>
                        Gerencie sua conta gov.br, acompanhe os serviços solicitados e assine documentos digitalmente.
                    </div> 
                     )}        
                    <PainelPrincipal/>
                      
                </div>
            </div>
        </Fragment>
    )

}