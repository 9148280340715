import { DateTime } from "luxon";

/**
 * FORMATO DOS DADOS RETORNADOS DA API
 * {
 *  "retorno": [
 *    {
 *      "servico": 9665,
 *      "protocolo": "0001AC.20171212.Felipe_001",
 *      "etapa": "Final",
 *      "dataEtapa": "27/04/2024",
 *      "situacaoEtapa": "Finalizada",
 *      "dataSituacaoEtapa": "27/04/2024",
 *      "orgao": 58108,
 *      "cpfCidadao": "88558983100",
 *      "linkVerResponder": "https://www.gov.br/pt-br/servicos/obter-o-certificado-internacional-de-vacinacao-e-profilaxia",
 *      "flagBotoes": 1/2 (1 = "Ver", 2 = "Responder"),
 *      "dataRequisicao": "27/04/2024",
 *      "dataFechamento": "27/04/2024",
 *      "situacaoServico": 2,
 *      "nomeServico": "Serviço teste - Cleuton20"
 *    }
 *  ],
 *  "paginaAtual": 0,
 *  "totalPaginas": 1
 * }
 *
 */
export function ordenarMaisRecentePrimeiro(lista) {
  lista.sort(
    (a, b) =>
      converterDataHoraParaMilis(b["dataSituacaoEtapa"]) -
      converterDataHoraParaMilis(a["dataSituacaoEtapa"])
  );
}

export function ordenarMaisAntigosPrimeiro(lista) {
  lista.sort(
    (a, b) =>
      converterDataHoraParaMilis(a["dataSituacaoEtapa"]) -
      converterDataHoraParaMilis(b["dataSituacaoEtapa"])
  );
}

function converterDataHoraParaMilis(dataMesDiaAno) {
  return DateTime.fromFormat(dataMesDiaAno, "dd/MM/yyyy'T'HH:mm:ss'Z'", {
    zone: "utc",
  }).toMillis();
}
