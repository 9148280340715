import { configureStore } from '@reduxjs/toolkit';

import usuarioReducer from './features/usuario/usuarioSlice';
import aplicacaoReducer from './features/aplicacao/aplicacaoSlice';

export default configureStore({
  reducer: {
    usuarioStore: usuarioReducer,
    aplicacaoStore: aplicacaoReducer,
  },
})