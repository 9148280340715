import { useEffect, useState } from "react";
import api from "../../services/api";

export function useFetch(url, config) {
  const [dados, setDados] = useState();
  const [erro, setErro] = useState(null);
  const [carregando, setCarregando] = useState(true);

  useEffect(() => {
    setCarregando(true);
    api
      .get(url, config)
      .then((resposta) => {
        if (resposta.status === 200) {
          setDados(resposta.data);
        }
        if (erro) setErro(null);
      })
      .catch((err) => setErro(err))
      .finally(() => setCarregando(false));
  }, [url, config]);

  return {
    dados,
    erro,
    carregando,
  };
}
