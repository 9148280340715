import { Container } from "./styles";

export function MensagemNaoHaItensHistorico() {
  return (
    <Container>
      <img src="./07.png" />
      <div>
        <h5>Nenhum serviço encontrado</h5>
        <p>
          Refine sua pesquisa, configurando os parâmetros do filtro avançado
          logo acima e tente novamente.
        </p>
      </div>
    </Container>
  );
}
