import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useFetch } from "../../Components/PaginaHistoricoDeServicos/useFetch";
import {
  ordenarMaisAntigosPrimeiro,
  ordenarMaisRecentePrimeiro,
} from "../../Components/PaginaHistoricoDeServicos/utils/ordenarListaServicos";

const ContextoHistoricoServicos = createContext();

export function ProviderHistoricoServicos({ children }) {
  const usuario = useSelector((state) => state.usuarioStore.usuario);
  const [cpfCnpj, setCpfCnpj] = useState(usuario ? usuario.cpf : "");
  const { dados, carregando, erro } = useFetch(
    `/api/servicosCidadao?cpfCnpjCidadao=${cpfCnpj}`
  );
  const [ordenacao, setOrdenacao] = useState("MAIS_RECENTE_PRIMEIRO");
  const [listaServicos, setListaServicos] = useState([]);
  const [forceReload, setForceReload] = useState(false);

  useEffect(() => {
    if (erro) {
      setListaServicos([]);
    } else if (dados) {
      const servicosOrdenados = getServicosOrdenados(dados);
      setListaServicos(servicosOrdenados);
    }
  }, [dados, ordenacao, erro, forceReload]);

  const getServicosOrdenados = useCallback(
    (lista) => {
      let listaOrdenada = [...lista];

      if (ordenacao === "MAIS_RECENTE_PRIMEIRO") {
        ordenarMaisRecentePrimeiro(listaOrdenada);
      } else {
        ordenarMaisAntigosPrimeiro(listaOrdenada);
      }

      return listaOrdenada;
    },
    [ordenacao]
  );

  const atualizaCpfCnpj = useCallback(
    (novoCpfCnpj) => {
      if (!novoCpfCnpj) return;

      if (novoCpfCnpj === cpfCnpj) {
        setForceReload((prev) => !prev);
      } else {
        setCpfCnpj(novoCpfCnpj);
      }
    },
    [cpfCnpj]
  );

  const usarCpfEmVezDeCnpj = useCallback(() => {
    setForceReload((prev) => !prev);
    if (cpfCnpj === usuario.cpf) {
      setForceReload((prev) => !prev);
    } else {
      setCpfCnpj(usuario.cpf);
    }
  }, [usuario, cpfCnpj]);

  const mudarOrdenacao = useCallback((novaOrdenacao) => {
    setOrdenacao(novaOrdenacao);
  }, []);

  const clearListaServicos = useCallback(() => {
    setListaServicos([]);
  }, []);

  const value = {
    carregando,
    erro,
    atualizaCpfCnpj,
    usarCpfEmVezDeCnpj,
    mudarOrdenacao,
    ordenacao,
    listaServicos,
    clearListaServicos,
  };

  return (
    <ContextoHistoricoServicos.Provider value={value}>
      {children}
    </ContextoHistoricoServicos.Provider>
  );
}

export function useContextoHistoricoServicos() {
  const contexto = useContext(ContextoHistoricoServicos);

  if (!contexto) {
    throw new Error(
      '"useDadosHistorico" precisa ser usado dentro de um "ProviderHistoricoServicos"'
    );
  }

  return contexto;
}
