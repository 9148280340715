import React, {Fragment, useEffect} from 'react';
import { Link, useHistory } from 'react-router-dom';
import BRBreadcrumb from '@govbr/dsgov/dist/components/breadcrumb/breadcrumb';

function addListenersGovBr () {
    const breadcrumbList = []
    for (const brBreadcrumb of window.document.querySelectorAll('.br-breadcrumb')) {
        breadcrumbList.push(new BRBreadcrumb('br-breadcrumb', brBreadcrumb))
    }
}

export default function Breadcrumb ({caminho}) {

    const history = useHistory();
    
    useEffect(() => {
       addListenersGovBr();
    }, [caminho]);

    return (
        <Fragment>
            <div className="br-breadcrumb">
                <ul className="crumb-list">
                    <li className="crumb home">
                        <div className="br-button circle" onClick={() => history.push('/')}><span className="sr-only">Página inicial</span><i className="icon fas fa-home"></i></div>
                    </li>
                    
                    {caminho &&  caminho.map((c, index) => {
                        const ultimaLinha = (index === (caminho.length - 1));
                        return (
                            <li key={index} className="crumb" data-active={ultimaLinha ? 'active': ''}>
                                <i className="icon fas fa-chevron-right"/>
                                {ultimaLinha === false &&
                                    <Link to={c.link}>{c.texto}</Link>
                                }
                                {ultimaLinha === true &&
                                    <span>{c.texto}</span>
                                }
                            </li>
                            )
                        }
                    )}
                </ul>
            </div>
            <span className="br-divider"></span>
        </Fragment>
    )

}
