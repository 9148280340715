import React, {Fragment, useState} from 'react';
import CardDocumento from '../CardDocumento';
import { cpfComMascara } from '../../../scripts/textUtil';

import ModalConfirmacao from '../../ModalConfirmacao';
import CardAumentarNivelConta from '../../CardAumentarNivelConta';
import config from '../../../config';

export default function CarteiraBronze (props) {
    const documentos = props.documentos;
    const [exibeModal, setExibeModal] = useState(false);
    const corpoModal = <p>A carteira de documentos é uma funcionalidade disponível para contas <strong>prata</strong> ou <strong>ouro</strong>. Para acessar os documentos, <strong>aumente o nível</strong> da sua conta gov.br.</p>;
    console.log("passei bronze")
    return (
        <Fragment>
             <ModalConfirmacao visivel={exibeModal} 
                corpo={corpoModal}
                confirmarLabel = 'Aumentar Nível'
                onConfirmar={() => {
                        setExibeModal(false);
                        return window.location.assign(config.govbrConfiabilidadesUrl);
                    }
                }
                cancelarLabel = 'Cancelar'
                onCancelar={()=> setExibeModal(false)}
            />
            <div className='cabecalho_painel'>
                <p>Aqui você visualiza os documentos digitais que tiver adicionado à sua Carteira, utilizando o <b>aplicativo gov.br</b>.</p>
                <p>Para visualizar outros documentos, além do CPF, você precisa aumentar o nível da sua conta gov.br, clicando em “Aumentar nível”, no box abaixo.</p>
                <br/>
                <p>Utilize o aplicativo gov.br para acessar mais funcionalidades como favoritar, compartilhar ou excluir documentos digitais da sua carteira.</p>
            </div>

            <div className="row">
                <div className="col-12 col-sm-5 col-md-7 col-lg-8">
                    {documentos && 
                        <div className="row">
                            {documentos.map((documento, index) => 
                                <div className='col-12 col-sm-12 col-md-8 col-lg-4' key={index}>
                                    <CardDocumento imagemDocumento={`data:image/png;base64,${documento.card.background}`} 
                                        tituloDocumento={documento.card.titulo.toUpperCase()}
                                        numeroIdentificacao={documento.tipoDoc === 'CPF' ? cpfComMascara(documento.card.identificacao.valor) : documento.card.identificacao.valor}
                                        labelIdentificacao={documento.card.identificacao.label}
                                        nomeDocumento={documento.descricao} 
                                        nomeOrgao={documento.orgao}
                                        corFonte={documento.tipoDoc !== 'CPF' ? '#000000' : '#ffffff'}
                                        onClick={() => setExibeModal(true)}/>
                                </div>
                            )
                        }
                        </div>
                    }

                </div>
                <div className="col-12 col-sm-7 col-md-5 col-lg-4">
                    <div className="row">
                        <div className="col-12">
                            <CardAumentarNivelConta/>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}