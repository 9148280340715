import { createSlice } from '@reduxjs/toolkit';

export const aplicacaoSlice = createSlice({
  name: 'aplicacao',
  initialState: {
    modalAssinaturaDocumentoBronze: false,
    mensagem: {},
    emProcessamento: false,
    textoModalEmProcessamento: 'Em processamento.'
  },
  reducers: {
    mostraModalAssinaturaDocumentoBronze: (state) => {
        state.modalAssinaturaDocumentoBronze = true;
    },
    escondeModalAssinaturaDocumentoBronze: (state) => {
      state.modalAssinaturaDocumentoBronze = false;
    },
    exibeMensagemSucesso: (state, action) => {
      const {titulo, texto} = action.payload;
      state.mensagem = {tipo: 'SUCESSO', titulo, texto};
    },
    exibeMensagemErro: (state, action) => {
      const {titulo, texto} = action.payload;
      state.mensagem = {tipo: 'ERRO', titulo, texto};
    },
    exibeMensagemInformativa: (state, action) => {
      const {titulo, texto} = action.payload;
      state.mensagem = {tipo: 'INFORMACAO', titulo, texto};
    },
    exibeMensagemAlerta: (state, action) => {
      const {titulo, texto} = action.payload;
      state.mensagem = {tipo: 'ALERTA', titulo, texto};
    },
    escondeMensagem: (state) => {
      state.mensagem = {};
    },
    exibeEmProcessamento: (state, action) => {
      state.emProcessamento = true;
      const texto = action && action.payload && action.payload.texto ? action.payload.texto : 'Em processamento';
      state.textoModalEmProcessamento = texto;
     },
    escondeEmProcessamento: (state) => {
        state.emProcessamento = false;
    },
  },
})

export const { mostraModalAssinaturaDocumentoBronze, escondeModalAssinaturaDocumentoBronze, 
               exibeMensagemSucesso, exibeMensagemErro, exibeMensagemInformativa, exibeMensagemAlerta, escondeMensagem,
               exibeEmProcessamento, escondeEmProcessamento 
              } = aplicacaoSlice.actions

export default aplicacaoSlice.reducer;