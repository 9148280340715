import React, { useEffect } from "react";
import { localStorageLogout } from "../../scripts/localStoreUtil";

const FrontChanelLogout = () => {

	useEffect(() => {
		localStorageLogout();
  	}, []);

	return(
		<div>
			Efetuando logout.
		</div>
	);
}

export default FrontChanelLogout;



