import styled from "styled-components";

export const Container = styled.div`
  .br-input > input[type="text"] {
    padding-right: 36px;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  .br-item.disabled {
    padding-left: var(--item-padding-x);
  }
`;
