import { BrRadioGroup } from "@govbr-ds/react-components";
import { Container, ContainerRadio } from "./styles";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useContextoHistoricoServicos } from "../../../Contexts/ProviderHistoricoServicos";
import { adicionarMascara } from "../../../helpers/mascaraCnpj";
import { CustomSelect } from "../../Select";

function RadioPessoaFisicaJuridica() {
  const usuario = useSelector((state) => state.usuarioStore.usuario);
  const { atualizaCpfCnpj, usarCpfEmVezDeCnpj, clearListaServicos } =
    useContextoHistoricoServicos();

  const opcoes = useMemo(
    () => [
      {
        id: "pessoa-fisica",
        value: "pessoa-fisica",
        label: "Pessoa Física",
      },
      {
        id: "pessoa-juridica",
        value: "pessoa-juridica",
        label: "Pessoa Jurídica",
      },
    ],
    []
  );

  const [opcaoSelecionada, setOpcaoSelecionada] = useState(opcoes[0].value);

  const mostraComponente = useMemo(() => {
    return (
      usuario.lista_cnpjs &&
      Array.isArray(usuario.lista_cnpjs) &&
      usuario.lista_cnpjs.length > 0
    );
  }, [usuario]);

  const listaCnpj = useMemo(() => {
    if (mostraComponente) {
      return usuario.lista_cnpjs.map(({ cnpj, razaoSocial }) => {
        const cnpjComMascara = adicionarMascara(cnpj);
        return {
          label: `${cnpjComMascara} - ${razaoSocial}`,
          value: cnpj,
        };
      });
    }

    return [];
  }, [usuario]);

  const handleChangePessoaFisicaOuJuridica = useCallback(
    (evento) => {
      setOpcaoSelecionada(evento.target.value);
      if (evento.target.value === "pessoa-fisica") {
        atualizaCpfCnpj(usuario.cpf);
      } else {
        clearListaServicos();
      }
    },
    [atualizaCpfCnpj, clearListaServicos]
  );

  const handleOnClickSelect = useCallback(
    (evento) => {
      const cnpj = evento.target.value;
      atualizaCpfCnpj(cnpj);
    },
    [atualizaCpfCnpj]
  );

  return (
    <Container>
      <ContainerRadio className="mt-5">
        <BrRadioGroup
          options={opcoes}
          name="pessoa-fisica-ou-juridica"
          onChange={handleChangePessoaFisicaOuJuridica}
          value={opcaoSelecionada}
        />
      </ContainerRadio>

      {opcaoSelecionada === "pessoa-juridica" && (
        <CustomSelect
          options={listaCnpj}
          placeholder="Selecione uma Pessoa Jurídica"
          onClick={handleOnClickSelect}
          onChange={handleOnClickSelect}
        />
      )}
    </Container>
  );
}

export default RadioPessoaFisicaJuridica;
