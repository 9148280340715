import React, {Fragment} from 'react';
import { useSelector } from 'react-redux';
import Modal from '../Modal';

export default function ModalEmProcessamento (props) {

    const emProcessamento = useSelector((state) => state.aplicacaoStore.emProcessamento);
    const textoModalEmProcessamento = useSelector((state) => state.aplicacaoStore.textoModalEmProcessamento);
    
    return (
        <Fragment>
            <Modal visivel={emProcessamento} classModal="is-xsmall rounder-md" >
                <div className="container-fluid p-1 p-sm-4">
                    <div className="loading medium"/>
                    <br/>
                    <span className="rotulo">{textoModalEmProcessamento}</span>
                </div>
            </Modal>
        </Fragment>
    )
}