import { Container, MensagemErroDatas } from "./styles.js";
import { TagsPeriodos } from "./TagsPeriodos";
import { DateTime } from "luxon";
import { IntervaloDataContainer, Footer } from "./styles.js";
import { BrInput, BrSelect, BrButton } from "@govbr-ds/react-components";
import { DateTimePicker } from "../DateTimePicker";
import { useFiltrosLocais } from "./useFiltrosLocais";
import { useCallback } from "react";
import classnames from "classnames";
import { CustomSelect } from "../../Select/index.js";

export function FiltrosAvancados({ fecharPopover }) {
  const {
    filtrosLocais,
    erroData,
    opcoesListaServicosSelect,
    refDateTimePickerDe,
    refDateTimePickerAte,
    tagsPeriodosRef,
    handleMudaSelect,
    handleSomentePendentes,
    handleMudaProtocolo,
    handleOnCloseDateTimePickerDe,
    handleOnCloseDateTimePickerAte,
    handleClicarLimparFiltros,
    limparErroDatas,
    handleClicarAplicarFiltros,
    setFiltrosLocais,
  } = useFiltrosLocais(() => {
    // irParaPrimeiraPagina();
    tentarFecharPopover();
  });

  const tentarFecharPopover = useCallback(() => {
    if (erroData) return;
    fecharPopover();
  }, [fecharPopover, erroData]);

  return (
    <Container>
      <div>
        <CustomSelect
          id="nomeServico"
          key={filtrosLocais.nomeServico}
          name="nomeServico"
          label="Nome do serviço"
          placeholder="Nome do serviço"
          defaultValue={filtrosLocais.nomeServico}
          onChange={handleMudaSelect}
          options={opcoesListaServicosSelect}
        />
      </div>

      <div>
        <BrInput
          onChange={handleMudaProtocolo}
          value={filtrosLocais.protocoloServico || ""}
          id="numero-protocolo"
          placeholder="Insira o protocolo"
          label="Número do protocolo"
        />
      </div>

      <TagsPeriodos
        ref={tagsPeriodosRef}
        atualizarPeriodoSugerido={(id, dataInicial, dataFinal) =>
          setFiltrosLocais((prev) => ({
            ...prev,
            periodoSugerido: { id, dataInicial, dataFinal },
            periodo: {
              dataInicial,
              dataFinal,
            },
          }))
        }
        periodoSugerido={filtrosLocais.periodoSugerido}
        limparErroDatas={limparErroDatas}
      />

      <IntervaloDataContainer>
        <DateTimePicker
          ref={refDateTimePickerDe}
          defaultDate={filtrosLocais.periodo.dataInicial || ""}
          label="A partir de"
          id="data-a-partir-de"
          onClose={handleOnCloseDateTimePickerDe}
          dateProps={{
            maxDate: DateTime.now().toFormat("dd/MM/yyyy").toString(),
          }}
          className={classnames({ erro: erroData })}
        />

        <DateTimePicker
          ref={refDateTimePickerAte}
          defaultDate={filtrosLocais.periodo.dataFinal || ""}
          label="Até"
          id="data-ate"
          onClose={handleOnCloseDateTimePickerAte}
          dateProps={{
            maxDate: DateTime.now().toFormat("dd/MM/yyyy").toString(),
          }}
          className={classnames({ erro: erroData })}
        />
      </IntervaloDataContainer>

      {erroData && (
        <MensagemErroDatas className="error-message">
          {erroData}
        </MensagemErroDatas>
      )}

      <div>
        <div className="br-switch small" role="presentation">
          <input
            id="somentePendentes"
            type="checkbox"
            name="somentePendentes"
            role="switch"
            checked={filtrosLocais.mostrarSomentePendentes || false}
            onChange={handleSomentePendentes}
          />
          <label htmlFor="somentePendentes">Pendentes para você</label>
        </div>
      </div>

      <Footer>
        <BrButton size="small" onClick={handleClicarLimparFiltros}>
          Limpar
        </BrButton>
        <BrButton
          size="small"
          type="button"
          primary
          onClick={handleClicarAplicarFiltros}
        >
          Aplicar
        </BrButton>
      </Footer>
    </Container>
  );
}
