import React from "react";
import { forwardRef } from "react";
import Header from "../../Header";
import { TemplateBase } from "..";

function DefaultHeader() {
  return (
    <Header className="br-header mb-4" id="header" data-sticky="data-sticky" />
  );
}
const Root = forwardRef(function Root(
  { children, breadcrumb, breadcrumbLinks, ...props },
  ref
) {
  let header = null;

  const modifiedChildren = React.Children.map(children, (child) => {
    if (child.type === TemplateBase.Header) {
      header = React.cloneElement(child, {});
      return null;
    }

    return child;
  });

  return (
    <div className="template-base" {...props} ref={ref}>
      {header ? header : <DefaultHeader />}
      {modifiedChildren}
    </div>
  );
});

export { Root };
