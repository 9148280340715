import { useEffect, useRef, useState } from "react";
import { Container } from "./styles";
import { ExtendedBRSelect } from "./ExtendedBRSelect";

export function CustomSelect(props) {
  const { label, id, placeholder, options, defaultValue, onChange } = props;
  const [selectedValue, setSelectedValue] = useState(defaultValue || "");

  const selectRef = useRef();

  useEffect(() => {
    let brSelect;

    const handleSelectionChange = () => {
      if (brSelect) {
        setSelectedValue(brSelect.selectedValue);
        onChange({
          target: {
            value: brSelect.selectedValue,
          },
        });
      }
    };

    if (selectRef.current) {
      brSelect = new ExtendedBRSelect(".br-select", selectRef.current);
      selectRef.current.addEventListener("change", handleSelectionChange);
    }

    return () => {
      if (selectRef.current) {
        selectRef.current.removeEventListener("change", handleSelectionChange);
      }
    };
  }, [defaultValue]);

  return (
    <Container className="br-select" ref={selectRef}>
      <div className="br-input">
        <label htmlFor={id}>{label}</label>
        <input id={id} type="text" placeholder={placeholder} />
        <button
          className="br-button"
          type="button"
          aria-label="Exibir lista"
          tabIndex="-1"
          data-trigger="data-trigger"
        >
          <i className="fas fa-angle-down" aria-hidden="true"></i>
        </button>
      </div>

      <div className="br-list" tabIndex="0">
        {options.map((option) => {
          const selected = option.value === defaultValue;

          return (
            <div
              className={`br-item ${selected ? "selected" : ""}`}
              tabIndex="-1"
              key={option.value}
            >
              <div className="br-radio">
                <input
                  id={option.value}
                  type="radio"
                  name={label}
                  value={option.value}
                />
                <label htmlFor={option.value}>{option.label}</label>
              </div>
            </div>
          );
        })}
      </div>
    </Container>
  );
}
