import { Container } from "./styles";

export function Close({ children, titulo, ...props }) {
  return (
    <Container
      className="br-button tertiary small circle"
      type="button"
      aria-label="Fechar popover"
      {...props}
    >
      <i className="fas fa-close"></i>
    </Container>
  );
}
