import styled from "styled-components";

export const ContainerBotoes = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--spacing-scale-4x);

  @media (min-width: 576px) {
    justify-content: start;
    gap: var(--spacing-scale-4x);
  }
`;

export const BotaoFiltroAvancado = styled.button`
  display: relative;
  overflow: visible;

  &.br-button:not(:disabled) {
    > i {
      width: calc(8px + 24px);
      padding: 8px;
      border-radius: 50%;
      margin-right: var(--spacing-scale-base);
    }

    :hover,
    :active,
    :focus {
      background-image: none;

      > i {
        background-image: linear-gradient(
          rgba(var(--interactive-rgb), var(--hover)),
          rgba(var(--interactive-rgb), var(--hover))
        );
      }
    }
  }

  > div.br-tag {
    position: absolute;
    left: 18px;
    top: -8px;
    font-size: 10px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 370px) {
    > span,
    i {
      font-size: var(--font-size-scale-base);
    }

    > i {
      margin: 0 !important;
    }
  }
`;

export const ContainerRadioPessoaFisicaJuridica = styled.div`
  display: ${(props) => (props.visible ? "initial" : "none")};
`;
