import React, {Fragment, useState,useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, connect } from 'react-redux';
import ModalBarraGov from '../ModalTelaCustomizada/ModalBarraGov';
import PdfViewer from '../PdfViewer';
import api from "../../services/api";
import { getPdfCpf, getPdfDocumentoWallet, getPdfCdi, getPdfCnh } from '../Carteira/geradorDocumentoPdf';
import { exibeMensagemSucesso, exibeMensagemErro, exibeEmProcessamento, escondeEmProcessamento } from '../../features/aplicacao/aplicacaoSlice';
import Carteira from '../Carteira';

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, exibeEmProcessamento, escondeEmProcessamento };

 function GeradorPDFBarraGov(props) {
  
    const usuario = useSelector((state) => state.usuarioStore.usuario);
    const [exibeDocumento, setExibeDocumento] = useState(true);
    const { search } = useLocation();
    const [documentoSelecionado, setDocumentoSelecionado] = useState([]);
    const [primeiro, setPrimeiro] = useState(true);

    const fechaModalDocumento = () => {
    setExibeDocumento(false);
    //  setDocumentoSelecionado(null);
    //  setTipoDocumentoSelecionado('');
    }

    const mostraCPF = (documento) => {
        
        try {
            const blobPDF =  getPdfCpf(usuario,documento.situacao,documento.dataNascimento, documento.nome);
            setDocumentoSelecionado(URL.createObjectURL(blobPDF));
            setTimeout(() => {
                setExibeDocumento(true);
            
            }, 1000);
        } catch (erro) {
            console.log("Não foi possível construir o documento CPF", erro);
        
        } 
    }
    
    const mostraCdi = (cdi) => {

        if(!cdi.infoCdi || !cdi.infoCdi.ra) {          
            return;
        }
        const ra = cdi.infoCdi.ra;
        api.get(`/api/carteira/documentos/cdi/${ra}`)
        .then(response => { 
            const imagemCdi = response.data;
            const blobPDF =  getPdfCdi(cdi, imagemCdi);
        if (blobPDF){
            setDocumentoSelecionado(URL.createObjectURL(blobPDF));
            setExibeDocumento(true);
        } else{          
        }
        })
        .catch(erro => {
            console.log("Não foi possível chamar o serviço corretamente", erro);        
        })
        .finally(() => {           
        });

    }
    const mostraDocumentoWallet = (tipoDocumento,identificacao) => {      
        // api.get(`/api/carteira/documentos/wallet/${tipoDocumento}`)
        api.get(`/api/carteira/documentos/wallet/${tipoDocumento}/${identificacao}`)
        .then(response => { 
            const documento = response.data;
                console.log(documento)
            const blobPDF =  getPdfDocumentoWallet(documento);
            setDocumentoSelecionado(URL.createObjectURL(blobPDF));
            setExibeDocumento(true);
        })
        .catch(erro => {
            console.log("Não foi possível chamar o serviço corretamente", erro);
            
        })
        .finally(() => {
            
        });
    }
    const mostraCnh = () => {
        api.get('/api/carteira/documentos/cnh')
        .then(response => { 
            const cnh = response.data;
            const blobPDF =  getPdfCnh(cnh);
            setDocumentoSelecionado(URL.createObjectURL(blobPDF));
            setExibeDocumento(true);
        })
        .catch(erro => {
            console.log("Não foi possível chamar o serviço corretamente", erro);
        //  props.exibeMensagemErro({titulo: 'Erro durante o carregamento da CNH:', texto:'Não foi possível carregar a CNH'});
        })
        .finally(() => {
        //  props.escondeEmProcessamento();
        });
    }
    useEffect(() => {
            
        if (primeiro){
           // console.log('chamei')  
          //  console.log(primeiro)          
            const searchParams = new URLSearchParams(search);
            const doc =searchParams.get("documento")
            const documento =  JSON.parse(doc)
            setDocumentoSelecionado(documento[0])                
            const tipoDocumento =documento[0].tipoDoc
           
            console.log(documento[0].tipoDoc)    
            setPrimeiro(false)
            if(tipoDocumento === 'CPF') {
                mostraCPF(documento[0]);
            } else if (tipoDocumento === 'CNH') {
                mostraCnh();
            } else if (tipoDocumento === 'CDI') { 
                mostraCdi(documento[0]);
            } else {
                mostraDocumentoWallet(tipoDocumento, documento[0].card.identificacao.valor);
            }  
        }
    }, []);
    

    return (
        <Fragment>
            <div className="container-lg">         
              <ModalBarraGov visivel={exibeDocumento} onClose={fechaModalDocumento}>      
                 <PdfViewer arquivo={documentoSelecionado} nomeArquivo={`${documentoSelecionado.tipoDoc}.pdf`} ocultaBotaoBaixar={usuario.isBronze} ocultaBotaoImprimir={usuario.isBronze} />               
                 </ModalBarraGov>    
            </div>
            </Fragment>
    )
}
export default connect(null,(mapDispatchToProps))(GeradorPDFBarraGov);