import { useState, useCallback, useMemo, useRef } from "react";
import {
  ESTADO_INICIAL,
  useFiltrosAvancados,
} from "../../../hooks/ContextoFiltrosAvancados/index.js";
import { DateTime } from "luxon";

export const useFiltrosLocais = (fecharPopover) => {
  const {
    filtros: filtrosGlobais,
    setFiltro: setFiltroGlobal,
    listaTodosServicos,
  } = useFiltrosAvancados();

  const [filtrosLocais, setFiltrosLocais] = useState(filtrosGlobais);
  const [erroData, setErroData] = useState("");
  const refDateTimePickerDe = useRef();
  const refDateTimePickerAte = useRef();
  const tagsPeriodosRef = useRef();

  const opcoesListaServicosSelect = useMemo(() => {
    if (listaTodosServicos && listaTodosServicos.length > 0) {
      const listaUnicos = [];

      listaTodosServicos.forEach((servico) => {
        if (!listaUnicos.includes(servico.nomeServico)) {
          listaUnicos.push(servico.nomeServico);
        }
      });

      return listaUnicos.map((item) => ({
        label: item,
        value: item,
      }));
    }

    return [];
  }, [listaTodosServicos]);

  const handleMudaSelect = useCallback((evento) => {
    if (Array.isArray(evento.target.value)) {
      // Usuario esta clicando em aplicar sem selecionar nenhum item da lista de nome de servico,
      // entao nao aplique os filtros
      return;
    }

    setFiltrosLocais((prev) => ({ ...prev, nomeServico: evento.target.value }));
  }, []);

  const handleSomentePendentes = useCallback((evento) => {
    setFiltrosLocais((prev) => ({
      ...prev,
      mostrarSomentePendentes: evento.target.checked,
    }));
  }, []);

  const handleMudaProtocolo = useCallback((evento) => {
    setFiltrosLocais((prev) => ({
      ...prev,
      protocoloServico: evento.target.value,
    }));
  }, []);

  const converterPeriodoPersonalizadoParaPeriodoSugerido = useCallback(
    (datas) => {
      if (!datas.dataInicial || !datas.dataFinal) return false;

      const dataInicial = DateTime.fromFormat(datas.dataInicial, "dd/MM/yyyy");
      const dataFinal = DateTime.fromFormat(datas.dataFinal, "dd/MM/yyyy");

      const diffDias = dataFinal.diff(dataInicial, "days").days;
      const diffMeses = dataFinal.diff(dataInicial, "months").months;
      const diffAnos = dataFinal.diff(dataInicial, "years").years;

      if (diffDias === 7) {
        //Datas selecionadas correspondem ao intervalo de uma semana
        return { id: "ultima-semana" };
      } else if (diffMeses === 1) {
        //Datas selecionadas correspondem ao intervalo de um mes
        return { id: "ultimo-mes" };
      } else if (diffAnos === 1) {
        //Datas selecionadas correspondem ao intervalo de um ano
        return { id: "ultimo-ano" };
      }

      return false;
    },
    []
  );

  const limparErroDatas = useCallback(() => {
    setErroData("");
  }, []);

  const validarDatas = useCallback((dataInicial, dataFinal) => {
    const dataInicialDt = DateTime.fromFormat(dataInicial, "dd/MM/yyyy");
    const dataFinalDt = DateTime.fromFormat(dataFinal, "dd/MM/yyyy");

    if (dataInicialDt > dataFinalDt) {
      setErroData("A data inicial não pode ser maior que a data final.");
      return false;
    }

    limparErroDatas();
    return true;
  }, []);

  const handleOnCloseDateTimePickerDe = useCallback(
    (novaData) => {
      setFiltrosLocais((prev) => {
        const periodo = converterPeriodoPersonalizadoParaPeriodoSugerido({
          ...prev.periodo,
          dataInicial: novaData.string,
        });

        validarDatas(novaData.string, prev.periodo.dataFinal);

        return {
          ...prev,
          periodo: {
            ...prev.periodo,
            dataInicial: novaData.string,
          },
          periodoSugerido: periodo ? periodo : ESTADO_INICIAL.periodoSugerido,
        };
      });

      tagsPeriodosRef.current?.desmarcarOpcaoSelecionada();
    },
    [tagsPeriodosRef, converterPeriodoPersonalizadoParaPeriodoSugerido]
  );

  const handleOnCloseDateTimePickerAte = useCallback(
    (novaData) => {
      setFiltrosLocais((prev) => {
        const periodo = converterPeriodoPersonalizadoParaPeriodoSugerido({
          ...prev.periodo,
          dataFinal: novaData.string,
        });

        validarDatas(prev.periodo.dataInicial, novaData.string);

        return {
          ...prev,
          periodo: {
            ...prev.periodo,
            dataFinal: novaData.string,
          },
          periodoSugerido: periodo ? periodo : ESTADO_INICIAL.periodoSugerido,
        };
      });
      tagsPeriodosRef.current?.desmarcarOpcaoSelecionada();
    },
    [tagsPeriodosRef, converterPeriodoPersonalizadoParaPeriodoSugerido]
  );

  const limparCamposDatas = useCallback(() => {
    refDateTimePickerDe.current.clear();
    refDateTimePickerAte.current.clear();
    limparErroDatas();
  }, [refDateTimePickerDe, refDateTimePickerAte]);

  const handleClicarLimparFiltros = useCallback(() => {
    setFiltrosLocais({
      ...ESTADO_INICIAL,
    });
    limparCamposDatas();
  }, [limparCamposDatas]);

  const handleClicarAplicarFiltros = useCallback(() => {
    if (erroData) {
      // Verificar se há erros antes de aplicar filtros
      refDateTimePickerDe.current.shake();
      refDateTimePickerAte.current.shake();
      return;
    }

    setFiltroGlobal(filtrosLocais);
    fecharPopover();
  }, [setFiltroGlobal, filtrosLocais, fecharPopover, erroData]);

  const removerFiltroLocal = useCallback(
    (chave) => {
      if (chave === "periodo") limparCamposDatas();

      setFiltrosLocais((prev) => ({
        ...prev,
        [chave]: ESTADO_INICIAL[chave],
      }));
    },
    [limparCamposDatas]
  );

  return {
    filtrosLocais,
    erroData,
    opcoesListaServicosSelect,
    refDateTimePickerDe,
    refDateTimePickerAte,
    tagsPeriodosRef,
    handleMudaSelect,
    handleSomentePendentes,
    handleMudaProtocolo,
    handleOnCloseDateTimePickerDe,
    handleOnCloseDateTimePickerAte,
    handleClicarLimparFiltros,
    handleClicarAplicarFiltros,
    removerFiltroLocal,
    limparErroDatas,
    setFiltrosLocais,
    limparCamposDatas,
  };
};
