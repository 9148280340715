import React from 'react';
import { ContainerLegenda, TextoLegenda } from './styles';


export default function LegendaPainel  (props) {

    return (
            <div>
                <ContainerLegenda>
                    <TextoLegenda className="legend">{props.titulo}</TextoLegenda>
                    <span className="br-divider my-3"></span>
                </ContainerLegenda>
            </div>
        )
}