export const localStorageLogin = (token, foto) => {
    localStorage.setItem('localStorage', JSON.stringify({token, foto}));
}

export const localStorageLogout = () => {
    localStorage.removeItem('localStorage');
}

export const getLocalStorage = () => {
    return localStorage.getItem('localStorage');
}