import styled from 'styled-components';

export const TextoTitulo = styled.h4 `
    vertical-align: middle;
    margin-top: 4px;
    margin-bottom: 0;
    color: var(--blue-warm-vivid-70);
`;

export const BarraTitulo = styled.span `
    margin-top: 4px !important;
`;




