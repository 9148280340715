import React, { Fragment } from 'react';
import { DivCard, DivTag, TextoTitulo, TextoConteudo } from './styles';
import {isMobile} from 'react-device-detect';

export function Tag (props) {
    const tag = props.tag;

    if (isMobile) {
        return (
            <TagBody tag={tag}/>
        )
    }

    return (
        <DivTag>
            <div className="col d-flex justify-content-end">
                <TagBody tag={tag}/>
            </div>
        </DivTag>
        )
}

function TagBody (props) {
    const tag = props.tag;
    const tipoTag = tag && tag.tipo ? tag.tipo : 'success';
    
    return (
        <Fragment>
            {tag && tag.exibir &&
                <div className={`br-tag count small justify-content-end ${tipoTag}`}>
                    <span>{tag.conteudo}</span>
                </div>                            
            }
            {(!tag || !tag.exibir) && 
                <span>&nbsp;</span>
            }
        </Fragment>
    )
}

export default function Card (props) {
    let auxicone = false
    const tag = props.tag;

    if (props.icone === 'fa-solid fa-landmark'){
        auxicone = true      
    } else{
        auxicone = false        
    }

    const linkEmiteCND  = () => {
       
        window.open("https://solucoes.receita.fazenda.gov.br/Servicos/certidaointernet/Pf/EmitirPGFN", "_blank");
    }

    if(isMobile) {
        return (
            <Fragment>
                <div className="d-flex mb-1" onClick={props.onClick}>
                    <div className="m-half p-1">
                        <i className={props.icone} aria-hidden="true" style={ !auxicone ? {fontSize: 25, color:'#0c326f'}: {fontSize: 25, color:'#D5D1D1'}}/>
                    </div>
                    <div className="m-half p-1 flex-fill">
                        <TextoTitulo  style={ !auxicone ? {color:'#0c326f'}: {color:'#B3B1B1'}}>{props.titulo}                        
                        { auxicone &&  
                            <TextoConteudo style= {{textAlign: 'left', fontSize: '10px',color:'#6E6464'}}>  
                             <b> Certidão temporariamente indisponível neste canal.</b>
                             &nbsp; Acesse o portal da 
                            <a onClick={() => linkEmiteCND()}> Receita Federal </a>para emitir a certidão.</TextoConteudo>                              
                         }
                        
                        </TextoTitulo>                        
                    </div>
                    
                    <div className="m-half p-1 d-flex justify-content-end">
                        <Tag tag={tag}/>  
                        <i className="icon fas fa-chevron-right" aria-hidden="true" style={{color:'#D5D1D1'}}/>
                    </div>
                   

                </div>
            </Fragment>
        )
    }
 
   
    return (
      
        <div className="divPainel">
            <DivCard className="br-card" onClick={props.onClick}>
                <Tag tag={props.tag}/>                
                <div className="card-header">
                    <div>
                        <div>
                            <div>                               
                                <button className="br-button circle" type="button" aria-label="Ícone ilustrativo">
                                <i aria-hidden="true"  className={props.icone} style={ !auxicone ? {fontSize: 25, color:'#0c326f'}: {fontSize: 25, color:'#B3B1B1'}}/>                                    
                                </button>
                            </div>
                            <TextoTitulo alinhamento='center' style={ !auxicone ? {color:'#0c326f'}: {color:'#B3B1B1'}}>{props.titulo}</TextoTitulo>
                        </div>
                        
                    </div>
                </div>
                <div className="card-content" style= {{padding: '14px 0px 0px 0px'}}>      
                    <TextoConteudo style={ !auxicone ? {color:'#0c326f',fontSize: '12px'}: {fontSize: '12px',   marginLeft:'0px', color:'#B3B1B1'}}>{props.conteudo}</TextoConteudo>
                  
                    { auxicone &&                                     
                        <TextoConteudo style= {{textAlign: 'justify',  marginTop:'10px',fontSize: '11px',color:'#6E6464'}}>                        
                            <i className='fa fa-ban'></i>
                        <b> Certidão temporariamente indisponível neste canal.</b>
                            &nbsp; Acesse o portal da 
                        <a onClick={() => linkEmiteCND()}> Receita Federal </a>para emitir a certidão.</TextoConteudo>                        
                     }
                   
                    </div>              

            </DivCard>
        </div>
    )
}