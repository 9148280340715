import { createContext, useContext } from "react";

const ScrollContext = createContext();

export function ScrollPageProvider({ children }) {
  const rolarPara = (elemento) => {
    if ("scrollBehavior" in document.documentElement.style) {
      // Verifica se o navegador suporta o comportamento de rolagem suave
      window.scrollTo({
        behavior: "smooth",
        top:
          elemento.getBoundingClientRect().top +
          (window.scrollY || window.pageYOffset),
      });
    } else {
      window.scrollTo(
        0,
        elemento.getBoundingClientRect().top +
          (window.scrollY || window.pageYOffset)
      );
    }
  };

  const rolarParaEvento = (uidEvento) => {
    const evento = document.querySelector(`[data-evento-uid="${uidEvento}"]`);
    if (evento) {
      rolarPara(evento);
      evento.classList.add("highlight-event");
      setTimeout(() => {
        evento.classList.remove("highlight-event");
      }, 2000);
    }
  };

  const rolarParaTopo = () => {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  };

  return (
    <ScrollContext.Provider
      value={{
        rolarParaTopo,
        rolarParaEvento,
      }}
    >
      {children}
    </ScrollContext.Provider>
  );
}

export function useRolarPagina() {
  const scrollContext = useContext(ScrollContext);
  return scrollContext;
}
