import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  top: calc(100% + 8px);
  z-index: 100;
  width: calc(100vw - 32px);
  max-width: 420px;
  left: 8px;
  right: 16px;
  border: var(--surface-border-solid-sm);
  border-color: var(--gray-10);
  padding: var(--spacing-scale-base) var(--spacing-scale-2x);
`;
