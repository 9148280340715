import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-scale-4x);

  .br-select {
    max-width: unset;
  }

  .br-switch input:checked + label:before {
    border: 1px solid #6493e6;
    background-color: #d7e4fd;
  }

  .br-switch input:not(:checked) + label:before {
    border: 1px solid var(--gray-30);
    background-color: var(--pure-0);
  }

  .br-switch input:not(:checked) + label:after {
    font-family: "Font Awesome 5 Free";
    content: "\f00d" !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2px;
    background-color: var(--gray-30);
    color: var(--background-light);
    font-size: var(--font-size-scale-down-01);
  }

  .br-switch input:checked + label:after {
    font-family: "Font Awesome 5 Free";
    content: "\f00c" !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2px;
    color: var(--background-light);
    font-size: var(--font-size-scale-down-01);
  }

  input::placeholder {
    color: var(--gray-60);
  }

  input {
    font-weight: var(--font-weight-semi-bold);
  }
`;

export const Label = styled.label`
  font-size: var(--font-size-scale-up-01);
  display: block;
  margin-bottom: 0px;
  font-weight: 600;
`;

export const IntervaloDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--spacing-scale-base);

  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  @media (min-width: 420px) {
    flex-direction: row;
  }

  .erro input {
    border: 2px solid var(--red-vivid-50);
  }

  .erro i {
    color: var(--red-vivid-50);
  }
`;

export const MensagemErroDatas = styled.span`
  color: var(--red-vivid-50);
  font-size: var(--font-size-scale-down-01);
  margin-top: calc(var(--spacing-scale-3xh) * -1);
`;

export const Footer = styled.footer`
  display: flex;
  gap: var(--spacing-scale-base);
  justify-content: end;
  padding-bottom: var(--spacing-scale-base);
`;
