import React, {Fragment, useState, useEffect} from 'react';
import { useSelector, connect } from 'react-redux';
import HeaderPagina from '../HeaderPagina';
import Header from '../Header';
import CarteiraBronze from './CarteiraBronze';
import CarteiraOuroPrata from './CarteiraOuroPrata';
import ModalTelaCustomizada from '../ModalTelaCustomizada';
import PdfViewer from '../PdfViewer';
import api from "../../services/api";
import { getPdfCpf, getPdfDocumentoWallet, getPdfCdi, getPdfCnh } from './geradorDocumentoPdf';
import { exibeMensagemSucesso, exibeMensagemErro, exibeEmProcessamento, escondeEmProcessamento } from '../../features/aplicacao/aplicacaoSlice';
import { useHistory } from 'react-router-dom';

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, exibeEmProcessamento, escondeEmProcessamento };

function Carteira (props) {
    
    const usuario = useSelector((state) => state.usuarioStore.usuario);
    const caminhoBreadcrumb = [{texto: 'Carteira de Documentos'}];
    const [exibeDocumento, setExibeDocumento] = useState(false);
    const [documentos, setDocumentos] = useState([]);
    const [tipoDocumentoSelecionado, setTipoDocumentoSelecionado] = useState('');
    const [documentoSelecionado, setDocumentoSelecionado] = useState(null);
    const history = useHistory();

    useEffect(() => {
        if (usuario.isCNPJ) {
            history.push('/');
        }
        props.exibeEmProcessamento({texto: 'Carregando carteira de documentos'});
        api.get('/api/carteira/documentos')
        .then(response => { 
            const documentos = response.data;
            setDocumentos(documentos);
        })
        .catch(erro => {
            console.log("Não foi possível chamar o serviço corretamente", erro);
            props.exibeMensagemErro({titulo: 'Erro durante o carregamento da carteira de documentos:', texto:'Não foi possível carregar a carteira de documentos'});
        })
        .finally(() => {
            props.escondeEmProcessamento();
        });
    }, []);

    const fechaModalDocumento = () => {
        setExibeDocumento(false);
        setDocumentoSelecionado(null);
        setTipoDocumentoSelecionado('');
    }

    const mostraDocumento = (documento) => {
        const tipoDocumento = documento.tipoDoc;
        setTipoDocumentoSelecionado(tipoDocumento);
       //console.log(documento)
        setDocumentoSelecionado(null);

        const cpf = 'CPF';
        const cnh = 'CNH';
        const cdi = 'CDI';

        if(tipoDocumento === cpf) {
            mostraCPF(documento);
        } else if (tipoDocumento === cnh) {
            mostraCnh();
        } else if (tipoDocumento === cdi) { 
            mostraCdi(documento);
        } else {
            mostraDocumentoWallet(tipoDocumento,documento.card.identificacao.valor);
        } 
    }

    const mostraCPF = (documento) => {
        props.exibeEmProcessamento({texto: 'Carregando CPF'});
        try {
            const blobPDF =  getPdfCpf(usuario,documento.situacao,documento.dataNascimento, documento.nome);
            setDocumentoSelecionado(URL.createObjectURL(blobPDF));
            setTimeout(() => {
                setExibeDocumento(true);
                props.escondeEmProcessamento();
            }, 1000);
        } catch (erro) {
            console.log("Não foi possível construir o documento CPF", erro);
            props.exibeMensagemErro({titulo: 'Erro durante o carregamento do documento:', texto:'Não foi possível carregar o documento CPF'});
            props.escondeEmProcessamento();
        } 
    }

    //const mostraDocumentoWallet = (tipoDocumento) => {
    const mostraDocumentoWallet = (tipoDocumento,identificacao) => {
    props.exibeEmProcessamento({texto: `Carregando ${tipoDocumento}` });

    // api.get(`/api/carteira/documentos/wallet/${tipoDocumento}`)
    api.get(`/api/carteira/documentos/wallet/${tipoDocumento}/${identificacao}`)
    .then(response => { 
        const documento = response.data;
           // console.log(documento)
        const blobPDF =  getPdfDocumentoWallet(documento);
        setDocumentoSelecionado(URL.createObjectURL(blobPDF));
        setExibeDocumento(true);
    })
    .catch(erro => {
        console.log("Não foi possível chamar o serviço corretamente", erro);
        props.exibeMensagemErro({titulo: 'Erro durante o carregamento do documento:', texto:`Não foi possível carregar o documento ${tipoDocumento}`});
    })
    .finally(() => {
        props.escondeEmProcessamento();
    });
    }

    const mostraCdi = (cdi) => {

        if(!cdi.infoCdi || !cdi.infoCdi.ra) {
            props.exibeMensagemErro({texto: cdi.infoCdi.situacaoDescricao});
            return;
        }

        const ra = cdi.infoCdi.ra;
        props.exibeEmProcessamento({texto: 'Carregando CDI'});

        api.get(`/api/carteira/documentos/cdi/${ra}`)
        .then(response => { 
            const imagemCdi = response.data;
            const blobPDF =  getPdfCdi(cdi, imagemCdi);
           if (blobPDF){
            setDocumentoSelecionado(URL.createObjectURL(blobPDF));
            setExibeDocumento(true);
           } else{
           props.exibeMensagemErro({texto: 'Não há certificado assinado cadastrado'});
           }
        })
        .catch(erro => {
            console.log("Não foi possível chamar o serviço corretamente", erro);
            props.exibeMensagemErro({titulo: 'Erro durante o carregamento do documento:', texto:'Não foi possível carregar o CDI'});
        })
        .finally(() => {
            props.escondeEmProcessamento();
        });

    }

    const mostraCnh = () => {
        props.exibeEmProcessamento({texto: 'Carregando CNH'});

        api.get('/api/carteira/documentos/cnh')
        .then(response => { 
            const cnh = response.data;
            const blobPDF =  getPdfCnh(cnh);
            setDocumentoSelecionado(URL.createObjectURL(blobPDF));
            setExibeDocumento(true);
        })
        .catch(erro => {
            console.log("Não foi possível chamar o serviço corretamente", erro);
            props.exibeMensagemErro({titulo: 'Erro durante o carregamento da CNH:', texto:'Não foi possível carregar a CNH'});
        })
        .finally(() => {
            props.escondeEmProcessamento();
        });
    }
    
    return (
        <Fragment>
           <Header/>
            <div className="container-lg">
                <ModalTelaCustomizada visivel={exibeDocumento} onClose={fechaModalDocumento}>
                    {documentoSelecionado &&
                        <PdfViewer arquivo={documentoSelecionado} nomeArquivo={`${tipoDocumentoSelecionado}.pdf`} ocultaBotaoBaixar={usuario.isBronze} ocultaBotaoImprimir={true}/>
                    }
                </ModalTelaCustomizada>

                <HeaderPagina caminhoBreadcrumb={caminhoBreadcrumb} titulo='Carteira de Documentos'/>

                {usuario && usuario.isBronze && 
                    <CarteiraBronze onSelecionaDocumento={mostraDocumento} documentos={documentos}/>
                }

                {usuario && (usuario.isOuro || usuario.isPrata) && 
                    <CarteiraOuroPrata onSelecionaDocumento={mostraDocumento} documentos={documentos}/>
                }
            </div>
        </Fragment>
    )
}


export default connect(null,(mapDispatchToProps))(Carteira);
