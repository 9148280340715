import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { BRRadio } from "./Checkbox";
import { useContextoHistoricoServicos } from "../../Contexts/ProviderHistoricoServicos";
import { Container, Conteudo, OpcaoOrdenacao } from "./styles";

export function ComboboxOrdemServicos() {
  const { ordenacao, mudarOrdenacao } = useContextoHistoricoServicos();
  const [aberto, setAberto] = useState(false);
  const [indiceOpcaoFocada, setIndiceOpcaoFocada] = useState(() => {
    return ordenacao === "MAIS_RECENTE_PRIMEIRO" ? 0 : 1;
  });
  const refBotaoAbrirCombobox = useRef(null);
  const refOpcoes = useRef([]);
  const refConteudo = useRef(null);
  const labelBotaoAbrirCombobox = useMemo(
    () =>
      ordenacao === "MAIS_RECENTE_PRIMEIRO" ? "Mais recentes" : "Mais antigos",
    [ordenacao]
  );

  const alternarAberturaCombobox = useCallback(() => {
    setAberto((aberto) => !aberto);
  }, []);

  const handlePressionarTeclaBotaoAbrirCombobox = useCallback(
    (evento) => {
      if (evento.key === " " || evento.key === "Enter") {
        evento.preventDefault();
        alternarAberturaCombobox();
      } else if (
        aberto &&
        (evento.key === "ArrowUp" || evento.key === "ArrowDown")
      ) {
        evento.preventDefault();
        const ultimoIndice = refOpcoes.current.length - 1;
        let novoIndice = indiceOpcaoFocada === null ? 0 : indiceOpcaoFocada;
        if (evento.key === "ArrowUp") {
          novoIndice = novoIndice <= 0 ? ultimoIndice : novoIndice - 1;
        } else {
          novoIndice = novoIndice >= ultimoIndice ? 0 : novoIndice + 1;
        }
        setIndiceOpcaoFocada(novoIndice);
      }
    },
    [aberto, indiceOpcaoFocada, alternarAberturaCombobox]
  );

  const handleSelecionarOpcao = useCallback(
    (novaOrdenacao) => {
      mudarOrdenacao(novaOrdenacao);
      setAberto(false);
    },
    [mudarOrdenacao]
  );

  const fecharComboboxSeClicarFora = useCallback(
    (evento) => {
      if (refConteudo.current && !refConteudo.current.contains(evento.target)) {
        setAberto(false);
      }
    },
    [refConteudo]
  );

  const handlePressionarTeclaComboboxAberto = useCallback(
    (evento) => {
      if (evento.key === "Escape") {
        setAberto(false);
      } else if (
        evento.key === "Enter" ||
        (evento.key === " " && indiceOpcaoFocada !== null)
      ) {
        const option = refOpcoes.current[indiceOpcaoFocada];
        if (option) {
          option.click();
        }
      }
    },
    [indiceOpcaoFocada, refOpcoes]
  );

  useEffect(() => {
    if (aberto) {
      document.addEventListener("keydown", handlePressionarTeclaComboboxAberto);
      document.addEventListener("click", fecharComboboxSeClicarFora);
    } else {
      document.removeEventListener(
        "keydown",
        handlePressionarTeclaComboboxAberto
      );
      document.removeEventListener("click", fecharComboboxSeClicarFora);
    }

    return () => {
      document.removeEventListener(
        "keydown",
        handlePressionarTeclaComboboxAberto
      );
      document.removeEventListener("click", fecharComboboxSeClicarFora);
    };
  }, [
    aberto,
    handlePressionarTeclaBotaoAbrirCombobox,
    fecharComboboxSeClicarFora,
  ]);

  useEffect(() => {
    if (aberto && refOpcoes) {
      refOpcoes.current[indiceOpcaoFocada].focus();
    }
  }, [aberto, indiceOpcaoFocada]);

  const handleCliqueAbrirCombobox = useCallback(() => {
    alternarAberturaCombobox();
  }, [alternarAberturaCombobox]);

  const handleMouseEnter = useCallback(
    (evento) => {
      const { target } = evento;
      const li = target.closest("li");

      if (li) {
        li.setAttribute("aria-selected", "true");
        li.setAttribute("data-selected", "true");
      }

      refOpcoes.current.forEach((opt) => {
        if (opt.closest("li") !== li) {
          opt.closest("li").removeAttribute("data-selected");
          opt.closest("li").removeAttribute("aria-selected");
        }
      });
    },
    [refOpcoes]
  );

  const handleClicarOpcaoOrdenacao = useCallback(
    (evento, index) => {
      evento.stopPropagation();
      const input = refOpcoes.current[index]?.querySelector("input");
      if (input) {
        input.click();
      }
    },
    [refOpcoes]
  );

  return (
    <Container
      className="br-button secondary small"
      type="button"
      role="combobox"
      ref={refBotaoAbrirCombobox}
      data-aberto={aberto}
      aria-expanded={aberto}
      aria-haspopup="listbox"
      aria-controls="combobox-ordenacao"
      onClick={handleCliqueAbrirCombobox}
      onKeyDown={handlePressionarTeclaBotaoAbrirCombobox}
    >
      <span>{labelBotaoAbrirCombobox}</span>

      <i className="fas fa-chevron-down"></i>
      <Conteudo
        className="br-card"
        aberto={aberto}
        ref={refConteudo}
        id="combobox-ordenacao"
        role="listbox"
      >
        <ul>
          <OpcaoOrdenacao
            {...(indiceOpcaoFocada === 0 && { "data-selected": "true" })}
            {...(indiceOpcaoFocada === 0 && { "aria-selected": "true" })}
            onMouseEnter={handleMouseEnter}
            onClick={(evento) => handleClicarOpcaoOrdenacao(evento, 0)}
          >
            <BRRadio
              role="option"
              ref={(ref) => (refOpcoes.current[0] = ref)}
              tabIndex={indiceOpcaoFocada === 0 ? "0" : "-1"}
              checked={ordenacao === "MAIS_RECENTE_PRIMEIRO"}
              onChange={() => handleSelecionarOpcao("MAIS_RECENTE_PRIMEIRO")}
              aria-selected={ordenacao === "MAIS_RECENTE_PRIMEIRO"}
              label="Mais recentes"
              id="MAIS_RECENTE_PRIMEIRO"
              value="MAIS_RECENTE_PRIMEIRO"
              onMouseEnter={handleMouseEnter}
            />
          </OpcaoOrdenacao>
          <OpcaoOrdenacao
            {...(indiceOpcaoFocada === 1 && { "data-selected": "true" })}
            {...(indiceOpcaoFocada === 1 && { "aria-selected": "true" })}
            onMouseEnter={handleMouseEnter}
            onClick={(evento) => handleClicarOpcaoOrdenacao(evento, 1)}
          >
            <BRRadio
              role="option"
              ref={(ref) => (refOpcoes.current[1] = ref)}
              tabIndex={indiceOpcaoFocada === 1 ? "0" : "-1"}
              checked={ordenacao === "MAIS_ANTIGO_PRIMEIRO"}
              onChange={() => handleSelecionarOpcao("MAIS_ANTIGO_PRIMEIRO")}
              aria-selected={ordenacao === "MAIS_ANTIGO_PRIMEIRO"}
              label="Mais antigos"
              id="MAIS_ANTIGO_PRIMEIRO"
              value="MAIS_ANTIGO_PRIMEIRO"
            />
          </OpcaoOrdenacao>
        </ul>
      </Conteudo>
    </Container>
  );
}
