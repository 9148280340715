import { isMobile } from "react-device-detect";
import { TextoTitulo, TextoConteudo, DivCard } from "./styles";
import { Tag } from ".";

function CardDesativadoMobile({ onClick, icone, titulo, tag, children }) {
  return (
    <div className="d-flex mb-1" onClick={onClick}>
      <div className="m-half p-1">
        <i
          className={icone}
          aria-hidden="true"
          style={{ fontSize: 25, color: "#D5D1D1" }}
        />
      </div>
      <div className="m-half p-1 flex-fill">
        <TextoTitulo style={{ color: "#B3B1B1" }}>
          {titulo}

          <TextoConteudo
            style={{
              textAlign: "left",
              fontSize: "10px",
              color: "#6E6464",
            }}
          >
            {children}
          </TextoConteudo>
        </TextoTitulo>
      </div>

      <div className="m-half p-1 d-flex justify-content-end">
        <Tag tag={tag} />
        <i
          className="icon fas fa-chevron-right"
          aria-hidden="true"
          style={{ color: "#D5D1D1" }}
        />
      </div>
    </div>
  );
}

export function CardDesativado({
  icone,
  titulo,
  conteudo,
  tag,
  onClick,
  children,
}) {
  if (isMobile) {
    return (
      <CardDesativadoMobile
        icone={icone}
        titulo={titulo}
        conteudo={conteudo}
        tag={tag}
        onClick={onClick}
        children={children}
      />
    );
  }

  return (
    <div className="divPainel">
      <DivCard className="br-card" onClick={onClick}>
        <Tag tag={tag} />
        <div className="card-header">
          <div>
            <div>
              <div>
                <button
                  className="br-button circle"
                  type="button"
                  aria-label="Ícone ilustrativo"
                >
                  <i
                    aria-hidden="true"
                    className={icone}
                    style={{ fontSize: 25, color: "#B3B1B1" }}
                  />
                </button>
              </div>
              <TextoTitulo alinhamento="center" style={{ color: "#B3B1B1" }}>
                {titulo}
              </TextoTitulo>
            </div>
          </div>
        </div>
        <div className="card-content" style={{ padding: "14px 0px 0px 0px" }}>
          <TextoConteudo
            style={{ fontSize: "12px", marginLeft: "0px", color: "#B3B1B1" }}
          >
            {conteudo}
          </TextoConteudo>

          <div
            style={{
              textAlign: "justify",
              marginTop: "10px",
              fontSize: "11px",
              color: "#6E6464",
            }}
          >
            <i className="fa fa-ban" aria-hidden="true" />
            {children}
          </div>
        </div>
      </DivCard>
    </div>
  );
}
