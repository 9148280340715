import { useState, useMemo } from "react";

export const usePaginacao = (lista = [], itensPorPagina = 10) => {
  const [paginaAtual, setPaginaAtual] = useState(0);

  const totalPaginas = useMemo(() => {
    return Math.ceil(lista.length / itensPorPagina);
  }, [lista, itensPorPagina]);

  const listaPaginada = useMemo(() => {
    const inicio = paginaAtual * itensPorPagina;
    const fim = inicio + itensPorPagina;
    return lista.slice(inicio, fim);
  }, [paginaAtual, lista, itensPorPagina]);

  const proximaPagina = () => {
    setPaginaAtual((paginaAtual) => Math.min(paginaAtual + 1, totalPaginas));
  };

  const paginaAnterior = () => {
    setPaginaAtual((paginaAtual) => Math.max(paginaAtual - 1, 0));
  };

  const pularParaPagina = (pagina) => {
    const numeroPagina = Math.max(0, pagina);
    setPaginaAtual(() => Math.min(numeroPagina, totalPaginas));
  };

  const irParaPrimeiraPagina = () => {
    setPaginaAtual(0);
  };

  return {
    listaPaginada,
    paginaAtual,
    totalPaginas,
    proximaPagina,
    paginaAnterior,
    pularParaPagina,
    irParaPrimeiraPagina,
  };
};
