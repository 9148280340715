import { useEffect, useMemo, useCallback, useRef, useState } from "react";
import LabelTab from "../LabelTab";
import ListaServicos from "../ListaServicos";
import BRTab from "@govbr/dsgov/dist/components/tab/tab";
import { Container, ContainerPaginacao } from "./styles";
import PaginacaoTipo2 from "../../PaginacaoTipo2";
import { useRolarPagina } from "../../../hooks/useRolarPagina";
import { usePaginacao } from "../../../hooks/usePaginacao";

const SITUACAO_SERVICO_CONCLUIDO = 2;
const SITUACAO_SERVICO_EM_ANDAMENTO = 1;

export function Historico({ listaTodosServicos }) {
  const refUl = useRef();
  const [mostrarIconeEsquerda, setMostrarIconeEsquerda] = useState(true);
  const [mostrarIconeDireita, setMostrarIconeDireita] = useState(true);
  const { rolarParaTopo } = useRolarPagina();
  const ITENS_POR_PAGINA = 10;

  const listaEmAndamento = useMemo(() => {
    return listaTodosServicos.filter(
      (item) => item.situacaoServico === SITUACAO_SERVICO_EM_ANDAMENTO
    );
  }, [listaTodosServicos]);

  const listaConcluidos = useMemo(() => {
    return listaTodosServicos.filter(
      (item) => item.situacaoServico === SITUACAO_SERVICO_CONCLUIDO
    );
  }, [listaTodosServicos]);

  const todosPaginacao = usePaginacao(listaTodosServicos, ITENS_POR_PAGINA);
  const emAndamentoPaginacao = usePaginacao(listaEmAndamento, ITENS_POR_PAGINA);
  const concluidosPaginacao = usePaginacao(listaConcluidos, ITENS_POR_PAGINA);

  const inicializaComponenteTabs = useCallback(() => {
    const abasList = [];
    for (const brTab of window.document.querySelectorAll(".br-tab")) {
      abasList.push(new BRTab("br-tab", brTab));
    }
  }, []);

  const rolarAbas = useCallback((passo = 1) => {
    refUl.current.scrollBy({ left: 150 * passo, behavior: "smooth" });
  }, []);

  const verificarScroll = () => {
    const ul = refUl.current;

    if (ul) {
      setMostrarIconeEsquerda(Math.floor(ul.scrollLeft) > 0);
      setMostrarIconeDireita(
        Math.ceil(ul.scrollLeft) < ul.scrollWidth - ul.clientWidth
      );
    }
  };

  useEffect(() => {
    inicializaComponenteTabs();

    const ul = refUl.current;
    if (ul) {
      verificarScroll();
      ul.addEventListener("scroll", verificarScroll);
      window.addEventListener("resize", verificarScroll);
    }

    return () => {
      if (ul) {
        ul.removeEventListener("scroll", verificarScroll);
      }
      window.removeEventListener("resize", verificarScroll);
    };
  }, []);

  useEffect(() => {
    rolarParaTopo();
  }, [
    todosPaginacao.paginaAtual,
    emAndamentoPaginacao.paginaAtual,
    concluidosPaginacao.paginaAtual,
  ]);

  const rolarAteAbaClicada = (evento) => {
    const { target } = evento;
    const aba = target.closest("li");
    if (aba) {
      const tabRect = aba.getBoundingClientRect();
      const ulRect = refUl.current.getBoundingClientRect();
      const scrollLeft =
        tabRect.left - ulRect.left - (ulRect.width - tabRect.width) / 2;
      refUl.current.scrollLeft += scrollLeft;
    }
  };

  return (
    <Container className="br-tab small">
      <nav className="tab-nav">
        <i
          style={{
            visibility: mostrarIconeEsquerda ? "visible" : "hidden",
          }}
          className="fas fa-chevron-left"
          onClick={() => rolarAbas(-1)}
        />

        <ul ref={refUl}>
          <li
            onClick={(evento) => rolarAteAbaClicada(evento)}
            className={"tab-item active"}
            title="Todos"
          >
            <LabelTab
              label="Todos"
              quantidade={listaTodosServicos.length}
              data-panel="panel-1"
            />
          </li>

          <li
            className={"tab-item"}
            onClick={(evento) => rolarAteAbaClicada(evento)}
          >
            <LabelTab
              label="Em andamento"
              quantidade={listaEmAndamento.length}
              data-panel="panel-2"
            />
          </li>

          <li
            className={"tab-item"}
            onClick={(evento) => rolarAteAbaClicada(evento)}
          >
            <LabelTab
              label="Concluído"
              quantidade={listaConcluidos.length}
              data-panel="panel-3"
            />
          </li>
        </ul>

        <i
          style={{
            visibility: mostrarIconeDireita ? "visible" : "hidden",
          }}
          className="fas fa-chevron-right"
          onClick={() => rolarAbas(1)}
        />
      </nav>

      <div className="tab-content">
        <div className="tab-panel active" id="panel-1">
          {todosPaginacao.listaPaginada && (
            <ListaServicos
              servicos={todosPaginacao.listaPaginada}
              prefixo="todos"
            />
          )}
          {todosPaginacao.totalPaginas > 1 && (
            <ContainerPaginacao>
              <PaginacaoTipo2
                totalPaginas={todosPaginacao.totalPaginas}
                paginaAtual={todosPaginacao.paginaAtual}
                onPageChange={todosPaginacao.pularParaPagina}
              />
            </ContainerPaginacao>
          )}
        </div>

        <div className="tab-panel" id="panel-2">
          {emAndamentoPaginacao.listaPaginada && (
            <ListaServicos
              servicos={emAndamentoPaginacao.listaPaginada}
              prefixo="em-andamento"
            />
          )}
          {emAndamentoPaginacao.totalPaginas > 1 && (
            <ContainerPaginacao>
              <PaginacaoTipo2
                totalPaginas={emAndamentoPaginacao.totalPaginas}
                paginaAtual={emAndamentoPaginacao.paginaAtual}
                onPageChange={emAndamentoPaginacao.pularParaPagina}
              />
            </ContainerPaginacao>
          )}
        </div>

        <div className="tab-panel" id="panel-3">
          {concluidosPaginacao.listaPaginada && (
            <ListaServicos
              servicos={concluidosPaginacao.listaPaginada}
              prefixo="concluidos"
            />
          )}
          {concluidosPaginacao.totalPaginas > 1 && (
            <ContainerPaginacao>
              <PaginacaoTipo2
                totalPaginas={concluidosPaginacao.totalPaginas}
                paginaAtual={concluidosPaginacao.paginaAtual}
                onPageChange={concluidosPaginacao.pularParaPagina}
              />
            </ContainerPaginacao>
          )}
        </div>
      </div>
    </Container>
  );
}
