import { useState } from "react";
import { Container } from "./styles";
import { Label } from "../styles";
import { useCallback } from "react";
import { DateTime } from "luxon";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import { useEffect } from "react";
import styles from "./styles.module.css";
import { useMemo } from "react";

export const TagsPeriodos = forwardRef(
  ({ atualizarPeriodoSugerido, periodoSugerido, limparErroDatas }, ref) => {
    const FORMATO_DATA = useMemo(() => "dd/MM/yyyy", []);

    const opcoes = useMemo(
      () => [
        {
          id: "ultima-semana",
          label: "7 dias",
          value: "ultima-semana",
        },
        {
          id: "ultimo-mes",
          label: "30 dias",
          value: "ultimo-mes",
        },
        {
          id: "ultimo-ano",
          label: "12 meses",
          value: "ultimo-ano",
        },
      ],
      []
    );

    const getPeriodoDoFiltro = useCallback(() => {
      if (periodoSugerido) {
        return opcoes.find(({ id }) => id === periodoSugerido.id);
      }
      return null;
    }, [periodoSugerido]);

    const [opcaoSelecionada, setOpcaoSelecionada] =
      useState(getPeriodoDoFiltro);

    const calculaPeriodo = useCallback((opcao) => {
      let dataInicial = "",
        dataFinal = "";

      if (!opcao) return { dataInicial, dataFinal };

      const { id } = opcao;

      switch (id) {
        case "ultima-semana":
          dataInicial = DateTime.now()
            .minus({ week: 1 })
            .toFormat(FORMATO_DATA);
          break;

        case "ultimo-mes":
          dataInicial = DateTime.now()
            .minus({ month: 1 })
            .toFormat(FORMATO_DATA);
          break;

        case "ultimo-ano":
          dataInicial = DateTime.now()
            .minus({ year: 1 })
            .toFormat(FORMATO_DATA);
          break;

        default:
          dataInicial = "";
          dataFinal = "";
          break;
      }

      return {
        dataInicial,
        dataFinal: DateTime.now().toFormat(FORMATO_DATA),
      };
    }, []);

    const handleSelecionarOpcao = useCallback(
      (opcao) => {
        if (
          !opcaoSelecionada ||
          (opcaoSelecionada && opcaoSelecionada.id !== opcao.id)
        ) {
          const { dataInicial, dataFinal } = calculaPeriodo(opcao);

          setOpcaoSelecionada(opcao);
          // Preencher DateTimePickers
          atualizarPeriodoSugerido(opcao.id, dataInicial, dataFinal);
          limparErroDatas();
        } else {
          setOpcaoSelecionada(null);
          atualizarPeriodoSugerido(null, "", "");
          limparErroDatas();
        }
      },
      [
        opcaoSelecionada,
        calculaPeriodo,
        atualizarPeriodoSugerido,
        limparErroDatas,
      ]
    );

    useEffect(() => {
      if (!periodoSugerido?.id) setOpcaoSelecionada(null);
      else setOpcaoSelecionada(periodoSugerido);
    }, [periodoSugerido]);

    // Função para desmarcar a opção selecionada
    const desmarcarOpcaoSelecionada = () => {
      setOpcaoSelecionada(null);
    };

    // Expor a função desmarcarOpcaoSelecionada
    useImperativeHandle(ref, () => ({
      desmarcarOpcaoSelecionada,
    }));

    return (
      <Container>
        <label>Últimos períodos</label>

        <div className="labels">
          {opcoes.map((opcao) => {
            const selecionado =
              Boolean(opcaoSelecionada) && opcao.id === opcaoSelecionada.id;

            return (
              <div
                key={opcao.id}
                className={`br-tag interaction-select ${selecionado ? "selected" : styles["outline-unselected"]
                  }`.trim()}
              >
                <input
                  id={opcao.id}
                  type="radio"
                  name={opcao.value}
                  value={opcao.value}
                  checked={selecionado}
                  onClick={() => handleSelecionarOpcao(opcao)}
                  onChange={() => handleSelecionarOpcao(opcao)}
                />
                <label htmlFor={opcao.id}>
                  <span>{opcao.label}</span>
                </label>
              </div>
            );
          })}
        </div>
      </Container>
    );
  }
);
