import { useMemo } from "react";
import {
  Card,
  CardContent,
  Detalhes,
  Situacao,
  Titulo,
  BotaoVerResponder,
  Pendencia,
  Divisor,
} from "./styles";
import { timestampParaDiaMesAnoHoraMinuto } from "../../utils/converterData";

function ItemServico({ servico }) {
  const buttonType = useMemo(() => {
    return servico.flagBotoes === 2 ? " primary" : "secondary";
  }, [servico]);

  const mostraFlag = useMemo(() => {
    if (servico.flagBotoes === 2) return true;
    return false;
  }, [servico]);

  return (
    <Card className="br-card">
      <CardContent className="card-content">
        <Titulo>{servico.nomeServico}</Titulo>
        <Divisor />

        <Detalhes>
          <span>
            Protocolo: <strong>{servico.protocolo}</strong>
          </span>

          <Situacao situacaoServico={servico.situacaoServico}>
            Situação:
            <strong>
              <i className="fas fa-clock"></i>
              {servico.situacaoEtapa}
            </strong>
          </Situacao>

          <span>
            Data de solicitação: <strong>{servico.dataRequisicao}</strong>
          </span>

          <span>
            Fase: <strong>{servico.etapa}</strong>
          </span>

          <span>
            Última atualização em:{" "}
            <strong>
              {timestampParaDiaMesAnoHoraMinuto(servico.dataSituacaoEtapa)}
            </strong>
          </span>
        </Detalhes>

        <Divisor />

        <BotaoVerResponder temBotaoAvaliacao={!!servico?.linkAvaliacao}>
          {mostraFlag && (
            <Pendencia>
              <i className="fas fa-triangle-exclamation"></i>
              Pendência
            </Pendencia>
          )}

          <a
            href={servico.linkVerResponder}
            className={`br-button ${buttonType} small`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {servico.flagBotoes === 1 ? "Ver" : "Responder"}
          </a>

          {!!servico?.linkAvaliacao && (
            <a
              className={`br-button secondary small`}
              href={servico.linkAvaliacao}
              rel="noopener noreferrer"
              target="_blank"
            >
              Avaliar
            </a>
          )}
        </BotaoVerResponder>
      </CardContent>
    </Card>
  );
}

export default ItemServico;
