import React, { useEffect, useMemo } from "react";
import { TemplateBase } from "../TemplateBase";
import Header from "../Header";
import {
  ContainerBotoes,
  BotaoFiltroAvancado,
  ContainerRadioPessoaFisicaJuridica,
} from "./styles";
import { Historico } from "./Historico";
import { ComboboxOrdemServicos } from "../ComboboxOrdemServicos";
import { connect, useSelector } from "react-redux";
import { exibeMensagemErro } from "../../features/aplicacao/aplicacaoSlice";
import ModalEmProcessamento from "../ModalEmProcessamento";
import HistoricoSkeleton from "./HistoricoSkeleton";
import { Popover } from "../Popover";
import { FiltrosAvancados } from "./FiltrosAvancados";
import { BrTag } from "@govbr-ds/react-components";
import { useFiltrosAvancados } from "../../hooks/ContextoFiltrosAvancados";
import HeaderPagina from "../HeaderPagina";
import RadioPessoaFisicaJuridica from "./RadioPessoaFisicaJuridica";
import { DescricaoPagina } from "./DescricaoPagina";

function PaginaHistoricoDeServicos(props) {
  const usuario = useSelector((state) => state.usuarioStore.usuario);
  const ITENS_POR_PAGINA = useMemo(() => 10, []);

  const {
    getQuantidadeFiltrosAtivos,
    erroAoConsultarHistorico,
    carregandoHistorico,
    listaServicos,
  } = useFiltrosAvancados();

  useEffect(() => {
    if (erroAoConsultarHistorico && erroAoConsultarHistorico.status >= 500) {
      props.exibeMensagemErro({
        texto: "Erro inesperado ao tentar recuperar histórico.",
      });
    }
  }, [erroAoConsultarHistorico]);

  const mostraComponenteSkeleton = useMemo(() => {
    return carregandoHistorico;
  }, [carregandoHistorico]);

  const mostraComponenteHistorico = useMemo(() => {
    return !carregandoHistorico;
  }, [carregandoHistorico]);

  const mostraRadioPessoaFisicaJuridica = useMemo(() => {
    return (
      !mostraComponenteSkeleton &&
      mostraComponenteHistorico &&
      usuario.lista_cnpjs &&
      Array.isArray(usuario.lista_cnpjs) &&
      usuario.lista_cnpjs.length > 0
    );
  }, [usuario, mostraComponenteHistorico]);

  const quantidadeFiltrosAtivos = getQuantidadeFiltrosAtivos();

  const caminhoBreadcrumb = useMemo(
    () => [{ texto: "Consultar serviços solicitados" }],
    []
  );

  return (
    <TemplateBase.Root>
      <ModalEmProcessamento />

      <TemplateBase.Header>
        <Header className="br-header" />
      </TemplateBase.Header>

      <TemplateBase.Main>
        <HeaderPagina
          titulo="Consultar serviços solicitados"
          caminhoBreadcrumb={caminhoBreadcrumb}
        />

        <DescricaoPagina />

        {mostraComponenteSkeleton && <HistoricoSkeleton />}

        <ContainerRadioPessoaFisicaJuridica
          visible={mostraRadioPessoaFisicaJuridica}
        >
          <RadioPessoaFisicaJuridica />
        </ContainerRadioPessoaFisicaJuridica>

        {mostraComponenteHistorico && (
          <ContainerBotoes>
            <Popover.Root>
              <Popover.Trigger>
                <BotaoFiltroAvancado
                  type="button"
                  className="br-button tertiary pl-0"
                >
                  {quantidadeFiltrosAtivos > 0 && (
                    <BrTag
                      value={`${quantidadeFiltrosAtivos}`}
                      color="success"
                      size="small"
                      type="count"
                    />
                  )}
                  <i className="fas fa-sliders"></i>

                  <span>Filtro avançado</span>
                </BotaoFiltroAvancado>
              </Popover.Trigger>

              <Popover.Conteudo
                seletoresNaoFecharAoClicar={[".flatpickr-calendar"]}
              >
                <FiltrosAvancados />
              </Popover.Conteudo>
            </Popover.Root>

            <ComboboxOrdemServicos />
          </ContainerBotoes>
        )}

        {mostraComponenteHistorico && (
          <Historico listaTodosServicos={listaServicos} />
        )}
      </TemplateBase.Main>
    </TemplateBase.Root>
  );
}

const mapDispatchToProps = {
  exibeMensagemErro,
};

export default connect(null, mapDispatchToProps)(PaginaHistoricoDeServicos);
