import React from "react";
import {
  BotaoVerResponder,
  Card,
  CardContent,
  Detalhes,
  Divisor,
  Situacao,
  Titulo,
} from "../../ListaServicos/ItemServico/styles";
import { Skeleton } from "../../../Skeleton";

function CardSkeleton() {
  return (
    <Card className="br-card">
      <CardContent className="card-content">
        <Titulo className="skeleton line">Título</Titulo>
        <Divisor />

        <Detalhes>
          <span>
            Protocolo: <Skeleton.Linha />
          </span>

          <Situacao situacao="Em andamento">
            Situação:
            <Skeleton.Linha />
          </Situacao>

          <span>
            Data de requisição: <Skeleton.Linha />
          </span>

          <span>
            Fase: <Skeleton.Linha />
          </span>
        </Detalhes>

        <Divisor />

        <BotaoVerResponder>
          <a style={{ marginLeft: "12px" }}>
            <Skeleton.Quadrado height="40px" width="140px" />
          </a>
        </BotaoVerResponder>
      </CardContent>
    </Card>
  );
}

export default CardSkeleton;
