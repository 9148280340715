import React, {Fragment} from 'react';
import { useSelector} from 'react-redux';
import { DivCard, DivCartaoSuperior, DivCartaoInferior, TituloDocumento as TextoNomeDocumento, TextoNomeCidadao, TextoLabelIdentificacao, TextoNumeroIdentificacao, TextoLabelSituacao, TextoSituacao, TextoNomeEmissorDocumento, TextoNomeDocumento as TextoDescricaoDocumento, DivCartao} from './styles';

export default function CardDocumento (props) {

    const usuario = useSelector((state) => state.usuarioStore.usuario);

    return (
        <Fragment>
            <DivCard className="br-card" onClick={props.onClick}>
                <div className="card-content">
                    <DivCartao>
                        <DivCartaoSuperior imagemDocumento={props.imagemDocumento}>
                            <TextoNomeDocumento corFonte={props.corFonte}>{props.tituloDocumento}</TextoNomeDocumento>
                            <TextoNomeCidadao corFonte={props.corFonte}>{usuario.nome}</TextoNomeCidadao>
                        </DivCartaoSuperior>
                        <DivCartaoInferior imagemDocumento={props.imagemDocumento}>
                            <div className="row">
                                <div className="col">
                                    <TextoLabelIdentificacao>{props.labelIdentificacao}</TextoLabelIdentificacao>
                                </div>
                                {props.labelSituacao &&
                                    <div className="col">
                                        <TextoLabelSituacao>{props.labelSituacao}</TextoLabelSituacao>
                                    </div>
                                }
                            </div>
                            <div className="row">
                                <div className="col">
                                    <TextoNumeroIdentificacao>{props.numeroIdentificacao}</TextoNumeroIdentificacao>
                                </div>
                                { props.situacao && 
                                    <div className="col">
                                        <TextoSituacao>{props.situacao}</TextoSituacao>
                                    </div>
                                }
                            </div>
                        </DivCartaoInferior>
                    </DivCartao>
                    <TextoNomeEmissorDocumento>{props.nomeOrgao}</TextoNomeEmissorDocumento>
                    <TextoDescricaoDocumento>{props.nomeDocumento}</TextoDescricaoDocumento>
                </div>
            </DivCard>
        </Fragment>
    )
}