import styled from 'styled-components';

export const LinkHeader = styled.a `
    font-weight: 500;
    &:hover {
        background-image: none !important;
    }
`;



