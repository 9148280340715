import React from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { escondeModalAssinaturaDocumentoBronze } from '../../features/aplicacao/aplicacaoSlice';
import config from '../../config';

import ModalConfirmacao from '../ModalConfirmacao';

const mapDispatchToProps = { escondeModalAssinaturaDocumentoBronze };

function ModalAssinarDocumentos  (props) {

    const tituloModal = 'Você precisa aumentar o nível da sua conta para assinar documentos digitalmente'
    const corpoModal = <p>Clique em "Aumentar nível" e siga as orientações para adquirir o nível <strong>Prata</strong> ou <strong>Ouro</strong>.</p>;
    const modalAssinaturaDocumentoBronze = useSelector((state) => state.aplicacaoStore.modalAssinaturaDocumentoBronze);
    
    return (
        <ModalConfirmacao visivel={modalAssinaturaDocumentoBronze} 
            titulo={tituloModal} 
            corpo={corpoModal}
            confirmarLabel = 'Aumentar Nível'
            onConfirmar={() => {
                   props.escondeModalAssinaturaDocumentoBronze();
                    return window.location.assign(config.govbrConfiabilidadesUrl);
                }
            }
            cancelarLabel = 'Cancelar'
            onCancelar={()=> props.escondeModalAssinaturaDocumentoBronze()}
        />
    )
       
}

export default connect(null,(mapDispatchToProps))(ModalAssinarDocumentos);