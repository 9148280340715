  export const formataDataInvertida = data => {
    if (!data || data.length !== 8){
      return data;
    }
    return data.substring(6) + '/' + data.substring(4,6) + '/' + data.substring(0,4);
  }

  const ehDataHoraInvertidaComTimeZone = strData => {
    var timestamp = Date.parse(strData);
    return ! isNaN(timestamp);
  }

  export const formataDataHoraInvertidaComTimeZone = strData => {
    if (! ehDataHoraInvertidaComTimeZone(strData)) {
      return strData;
    }
    const data = new Date(strData);
    return data.toLocaleDateString('pt-BR') + ' ' + data.toLocaleTimeString('pt-BR', {hour: '2-digit', minute:'2-digit'});
  }

  export const diaSemanaDataHoraInvertidaComTimeZone = strData => {
    if (! ehDataHoraInvertidaComTimeZone(strData)) {
      return strData;
    }

    const data = new Date(strData); 
    const dias = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
    return dias[data.getDay()];
  }