import React, {Fragment, useEffect} from 'react';
import {isMobile} from 'react-device-detect';
import Card from '../Card';
import config from '../../config';
import LegendaPainel from '../LegendaPainel';
import { useSelector } from 'react-redux'
//import BRCard from '@govbr/dsgov/dist/components/card/card';



function addListenersGovBr () {
/*    
    const listCard = []
    for (const [index, brCard] of window.document.querySelectorAll('.br-card').entries()) {
        listCard.push(new BRCard('br-card', brCard, index))
    }
*/
}

function goto(url){
    window.location.assign(url);
}

export default function PainelMinhaConta  (props) {
    const usuario = useSelector((state) => state.usuarioStore.usuario);

    useEffect(() => {
        addListenersGovBr();
     });

    return (
            <Fragment>
                
                <LegendaPainel titulo="Minha conta"/>
                    <div className='col'>
                    <div className={isMobile ? '' : 'row'}>
                    { !usuario.isCNPJ && (
                    <>
                        <Card icone="fas fa-clipboard-list" titulo="Dados Pessoais" conteudo="Ver e alterar dados pessoais e de contato" onClick={() => goto(config.govbrContasDadosPessoaisUrl)}/>
                        <Card icone="fas fa-lock" titulo="Segurança da conta" conteudo="Acessar o nível da conta, alterar senha e habilitar verificação em duas etapas" onClick={() => goto(config.govbrContasSegurancaUrl)}/>
                        <Card icone="fas fa-user-lock" titulo="Privacidade" conteudo="Ver histórico de login e gerenciar autorizações de uso dos dados" onClick={() => goto(config.govbrContasPrivacidadeUrl)}/>
                        </>
                    )}
                    { usuario.isCNPJ &&    
                        <Card icone="fas fa-key" titulo="Vincular empresas via e-CNPJ" conteudo="Gerencie os colaboradores que podem interagir com o governo representando sua empresa" onClick={() => goto(config.govbrVincularEmpresasUrl)}
                    />}
                    </div>
                </div>
            
            </Fragment>
        )
       
}