import React, { useCallback, useEffect } from "react";

export function Trigger({ children, aberto, fecharPopover, ...props }) {
  const handlePressionarTeclaPopoverAberto = useCallback(
    (evento) => {
      if (evento.key === "Escape") {
        fecharPopover();
      }
    },
    [fecharPopover]
  );

  useEffect(() => {
    if (aberto) {
      document.addEventListener("keydown", handlePressionarTeclaPopoverAberto);
    } else {
      document.removeEventListener(
        "keydown",
        handlePressionarTeclaPopoverAberto
      );
    }

    return () => {
      document.removeEventListener(
        "keydown",
        handlePressionarTeclaPopoverAberto
      );
    };
  }, [handlePressionarTeclaPopoverAberto]);

  return React.cloneElement(children, {
    "aria-label": aberto,
    "aria-expanded": aberto,
    "data-aberto": aberto,
    "aria-controls": "popover-id",
    "aria-haspopup": "dialog",
    ...props,
  });
}
