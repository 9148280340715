import React, {Fragment} from 'react';

export default function PaginacaoTipo2 ({ totalPaginas, paginaAtual, onPageChange }) {

    const PAGINAS_VISIVEIS = 10;
    const centro = PAGINAS_VISIVEIS/2;
    const tamanho = Math.min(totalPaginas, PAGINAS_VISIVEIS);
    if(paginaAtual > totalPaginas) {
        paginaAtual = 0;
    }
    let ultima = Math.min(totalPaginas, paginaAtual + centro);
    ultima = Math.max(tamanho, ultima);
    const primeira = Math.max(0, ultima - tamanho);

    const paginas = [];
    for (let pagina=primeira; pagina<ultima; pagina++){
        paginas.push(pagina)
    }	

    const paginaAnterior = Math.max(0, paginaAtual - 1);
    const paginaPosterior = Math.min(totalPaginas - 1, paginaAtual + 1);

   
    return (
        <Fragment>
            <div className='table-footer'>
                <nav className="br-pagination small" aria-label="Paginação de resultados" data-total="5" data-current="1">
                    <ul>
                        {paginaAtual > 0 &&
                            <li>
                                <button className="br-button circle" type="button" data-previous-page="data-previous-page" aria-label="Página anterior" 
                                    onClick={() => onPageChange(paginaAnterior)}>
                                    <i className="fas fa-angle-left" aria-hidden="true"></i>
                                </button>
                            </li>
                        }

                        { paginas.map((pagina, index) => 
                            <li  key={index} onClick={() => onPageChange(pagina)}>
                                <a className={`page ${pagina === paginaAtual ? 'active' : ''}`} href="/#" onClick={ev => {ev.preventDefault();}}>{pagina + 1}</a>
                            </li>								
                        )}
                        
                        {paginaAtual < (totalPaginas - 1) &&
                            <li>
                                <button className="br-button circle" type="button" data-next-page="data-next-page" aria-label="Página seguinte"
                                    onClick={() => onPageChange(paginaPosterior)}>
                                    <i className="fas fa-angle-right" aria-hidden="true"></i>
                                </button>
                            </li>
                        }
                    </ul>
                    </nav>
            </div>
        </Fragment>
    )
}