import { Buffer } from 'buffer';

export const base64ToBlob = (base64, tipo) => {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i);
    }
  
    return new Blob([bytes], { type: tipo });
};

export const strTobase64 = (str) => {
  if(! str){
    return str;
  }
  return Buffer.from(str).toString('Base64');
}

export const base64ToStr = (base64) => {
  let buff = Buffer.from(base64, 'base64');  
  return buff.toString('utf-8');
}