import React, {Fragment} from 'react';
import { DivBanner} from './styles';
import {isMobileOnly} from 'react-device-detect';
import imagemBanner from '../../images/banner-minha-area.png';
import imagemBannerMobile from '../../images/banner-arealogada-mobile.png';


export default function Banner (props) {

    return (
        <Fragment>           
                <DivBanner imagem={isMobileOnly? imagemBannerMobile : imagemBanner} altura={isMobileOnly? '71px': 'auto'}/>
        </Fragment>
    )
    
}