import { Root } from "./Root";
import { Trigger } from "./Trigger";
import { Conteudo } from "./Conteudo";
import { Close } from "./Close";

export const Popover = {
  Root,
  Trigger,
  Conteudo,
  Close,
};
