import React, {Fragment} from 'react';
import Modal from '../Modal';
import warning from '../../images/warning.png';
import { DivTituloMensagem, DivCorpoMensagem, DivImagem, ImagemIcone } from './styles';

export default function ModalConfirmacao (props) {
    
    return (
        <Fragment>
            <Modal visivel={props.visivel} classModal="rounder-lg" overflow='visible'>
                <DivImagem>
                    <ImagemIcone imagem={warning}/>
                </DivImagem>
                <div className="container-fluid p-1 p-sm-4">
                    <div className="br-modal-header">
                        <DivTituloMensagem className="br-modal-title" title={props.titulo}>{props.titulo}</DivTituloMensagem>
                        <DivCorpoMensagem className="mt-5">{props.corpo}</DivCorpoMensagem>
                    </div>
                    <div className="br-modal-footer pt-5">
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex justify-content-center mb-3">
                            <button className="br-button primary block auto-md" type="button" onClick={props.onConfirmar}>
                                {props.confirmarIcone && <i className={`${props.confirmarIcone} mr-1`} aria-hidden="true"></i>}
                                {props.confirmarLabel ? props.confirmarLabel : 'Confirmar'}
                            </button>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex justify-content-center">
                            <button className="br-button secondary block auto-md" type="button" onClick={props.onCancelar}>
                                {props.cancelarIcone && <i className={`${props.cancelarIcone} mr-1`} aria-hidden="true"></i>}
                                {props.cancelarLabel ? props.cancelarLabel : 'Cancelar'}
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </Fragment>
    )
}