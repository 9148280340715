import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { stringDataParaData } from "./utils";
import { useContextoHistoricoServicos } from "../../Contexts/ProviderHistoricoServicos";

const ContextoFiltrosAvancados = createContext();

export const ESTADO_INICIAL = {
  nomeServico: "",
  protocoloServico: "",
  mostrarSomentePendentes: false,
  periodo: {
    dataInicial: "",
    dataFinal: "",
  },
  periodoSugerido: {
    id: null,
    dataInicial: "",
    dataFinal: "",
  },
};

const FLAG_BOTAO_VER = 1;
const FLAG_BOTAO_RESPONDER = 2;

export function ProviderFiltrosAvancados({ children }) {
  const [filtros, setFiltros] = useState(ESTADO_INICIAL);
  const [listaServicosFiltrada, setListaServicosFiltrada] = useState([]);
  const {
    listaServicos,
    carregando: carregandoHistorico,
    erro: erroConsulta,
  } = useContextoHistoricoServicos();

  const atualizarFiltros = useCallback((novosFiltros) => {
    setFiltros((filtrosAnteriores) => {
      const filtrosAtualizados = {
        ...filtrosAnteriores,
        ...novosFiltros,
      };

      return filtrosAtualizados;
    });
  }, []);

  const aplicarFiltros = useCallback(() => {
    if (!Array.isArray(listaServicos)) {
      setListaServicosFiltrada([]);
      return;
    }

    const filtrosArray = [];

    if (filtros.nomeServico) {
      filtrosArray.push(
        (servico) =>
          servico.nomeServico.toLowerCase() ===
          filtros.nomeServico.toLowerCase()
      );
    }

    if (filtros.protocoloServico) {
      filtrosArray.push((servico) =>
        servico.protocolo
          .toLowerCase()
          .includes(filtros.protocoloServico.toLowerCase())
      );
    }

    if (filtros.mostrarSomentePendentes) {
      filtrosArray.push(
        (servico) => servico.flagBotoes === FLAG_BOTAO_RESPONDER
      );
    }

    const filtroDataInicial = filtros.periodo.dataInicial
      ? stringDataParaData(filtros.periodo.dataInicial)
      : null;

    const filtroDataFinal = filtros.periodo.dataFinal
      ? stringDataParaData(filtros.periodo.dataFinal)
      : null;

    if (filtroDataInicial) {
      filtrosArray.push(
        (servico) =>
          stringDataParaData(servico.dataRequisicao) >= filtroDataInicial
      );
    }

    if (filtroDataFinal) {
      filtrosArray.push(
        (servico) =>
          stringDataParaData(servico.dataRequisicao) <= filtroDataFinal
      );
    }

    const filtroPeriodoSugeridoInicial = filtros.periodoSugerido.dataInicial
      ? stringDataParaData(filtros.periodoSugerido.dataInicial)
      : null;
    const filtroPeriodoSugeridoFinal = filtros.periodoSugerido.dataFinal
      ? stringDataParaData(filtros.periodoSugerido.dataFinal)
      : null;

    if (filtroPeriodoSugeridoInicial) {
      filtrosArray.push(
        (servico) =>
          stringDataParaData(servico.dataRequisicao) >=
          filtroPeriodoSugeridoInicial
      );
    }

    if (filtroPeriodoSugeridoFinal) {
      filtrosArray.push(
        (servico) =>
          stringDataParaData(servico.dataRequisicao) <=
          filtroPeriodoSugeridoFinal
      );
    }

    const novaListaFiltrada = listaServicos.filter((servico) =>
      filtrosArray.every((filtro) => filtro(servico))
    );

    setListaServicosFiltrada(novaListaFiltrada);
  }, [filtros, listaServicos]);

  const getQuantidadeFiltrosAtivos = useCallback(() => {
    let contador = 0;

    // Verificar cada filtro individualmente
    if (filtros.nomeServico !== ESTADO_INICIAL.nomeServico) contador++;
    if (filtros.protocoloServico !== ESTADO_INICIAL.protocoloServico)
      contador++;
    if (
      filtros.mostrarSomentePendentes !== ESTADO_INICIAL.mostrarSomentePendentes
    )
      contador++;

    // Verificar o período, considerando 'periodo' e 'periodoSugerido' como o mesmo filtro
    const periodoAtivo =
      filtros.periodo.dataInicial ||
      filtros.periodo.dataFinal ||
      filtros.periodoSugerido.dataInicial ||
      filtros.periodoSugerido.dataFinal;

    if (periodoAtivo) contador++;

    return contador;
  }, [filtros]);

  useEffect(() => {
    if (erroConsulta) {
      //Zerar lista, caso haja algum erro na requisição
      setListaServicosFiltrada([]);
    } else if (listaServicos) {
      setListaServicosFiltrada(listaServicos);
      aplicarFiltros();
    }
  }, [listaServicos, erroConsulta]);

  useEffect(() => {
    aplicarFiltros();
  }, [filtros, listaServicos]);

  return (
    <ContextoFiltrosAvancados.Provider
      value={{
        filtros,
        setFiltro: atualizarFiltros,
        aplicarFiltros,
        getQuantidadeFiltrosAtivos,
        listaServicos: listaServicosFiltrada,
        listaTodosServicos: listaServicos,
        carregandoHistorico,
        erroAoConsultarHistorico: erroConsulta,
      }}
    >
      {children}
    </ContextoFiltrosAvancados.Provider>
  );
}

export function useFiltrosAvancados() {
  const contexto = useContext(ContextoFiltrosAvancados);

  if (contexto === undefined) {
    throw new Error(
      '"useFiltrosAvancados" precisa ser usado dentro de um "ProviderFiltrosAvancados"'
    );
  }

  return contexto;
}
