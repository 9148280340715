import React, { useEffect} from 'react';
import BRHeader from '@govbr/dsgov/dist/components/header/header';
import HeaderMenu from '../HeaderMenu';
import {LinkHeader} from './styles';

function addListenersGovBr () {
    const headerList = [];
    for (const brHeader of window.document.querySelectorAll('.br-header')) {
        headerList.push(new BRHeader('br-header', brHeader))
    }
}
export default function Header (props) {

    useEffect(() => {
        addListenersGovBr();
     });

    return (
        <header className="br-header" {...props}>
            <div className="container-lg">
                <div className="header-top">
                    <div className="header-logo">
                        <LinkHeader href="https://www.gov.br/pt-br">
                            <img src={require('../../images/govbr.png')} alt="govbr"/>
                        </LinkHeader>
                    </div>
                    <div className="header-actions mt-0">
                        <div className="header-links dropdown">
                            <button className="br-button circle small" type="button" data-toggle="dropdown" aria-label="Abrir Acesso Rápido">
                                <i className="fas fa-ellipsis-v" aria-hidden="true"></i>
                            </button>
                            <div className="br-list">
                                <LinkHeader className="br-item" href="https://www.gov.br/pt-br/orgaos-do-governo">Órgaos do Governo</LinkHeader>
                                <LinkHeader className="br-item" href="https://www.gov.br/acessoainformacao/pt-br">Acesso à informação</LinkHeader>
                                <LinkHeader className="br-item" href="http://www4.planalto.gov.br/legislacao/">Legislação</LinkHeader>
                                <LinkHeader className="br-item" href="https://www.gov.br/governodigital/pt-br/acessibilidade-digital">Acessibilidade</LinkHeader>
                            </div>
                        </div>
                        <div className="header-functions">
                            <div className="br-list">
                                <div className="align-items-center br-item">
                                    <button className="br-button circle small" type="button" aria-label="Funcionalidade 4">
                                        <i className="fas fa-adjust" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="header-login">
                            <HeaderMenu/>
                        </div>
                    </div>
                </div>
                <div data-target="#main-navigation"/>
            </div>
        </header>
    )

}

