import React, { forwardRef } from "react";

const Main = forwardRef(function Main({ children, ...props }, ref) {
  return (
    <main className="d-flex flex-fill mb-5" id="main" ref={ref}>
      <div className="container-lg d-flex flex-column">{children}</div>
    </main>
  );
});

export { Main };
