import React, {Fragment, useState} from 'react';
import { Document, Page, pdfjs } from "react-pdf";
import './styles.css'; 

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;


export default function PdfViewer (props) {

    const [totalPaginas, setTotalPaginas] = useState(null);
    const [paginaAtual, setPaginaAtual] = useState(1);
    const ocultaBotaoBaixar = props.ocultaBotaoBaixar;
    const ocultaBotaoImprimir = props.ocultaBotaoImprimir;

    function onDocumentLoadSuccess({ numPages: quantidadePaginas }) {
        setTotalPaginas(quantidadePaginas);
        setPaginaAtual(1);
    }
    
    function irPrimeiraPagina() {
        setPaginaAtual(1);
    }

    function irUltimaPagina() {
        setPaginaAtual(totalPaginas);
    }

    function mudaPagina(offset) {
        setPaginaAtual(prevPageNumber => prevPageNumber + offset);
    }
    
    function irPaginaAnterior() {
        mudaPagina(-1);
    }
    
    function irPaginaPosterior() {
        mudaPagina(1);
    }

    function download(arquivo) {
        var element = document.createElement('a');
        element.setAttribute('href', arquivo);
        element.setAttribute('download', props.nomeArquivo ? props.nomeArquivo : 'arquivo.pdf');
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    function print(arquivo) {
        let pdfFrame = document.body.appendChild(document.createElement('iframe'));
        pdfFrame.style.display = 'none';
        pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
        pdfFrame.src = arquivo;
    }

    return (
        <Fragment>
            <div className="row">
                <div className="col d-flex justify-content-left">
                    {(totalPaginas > 1) &&
                        <Fragment>
                            <button type="button" className="br-button secondary circle mt-3 mt-sm-0 ml-sm-3" hidden  disabled={paginaAtual <= 1} onClick={irPrimeiraPagina}>
                                <i className="fa-solid fa-backward-fast" aria-hidden="true"></i>
                            </button>
                            
                            <button type="button" className="br-button secondary circle mt-3 mt-sm-0 ml-sm-3" disabled={paginaAtual <= 1} onClick={irPaginaAnterior}>
                                <i className="fa-solid fa-backward-step" aria-hidden="true"></i>
                            </button>

                            <button type="button" className="br-button secondary circle mt-3 mt-sm-0 ml-sm-3" disabled={paginaAtual >= totalPaginas} onClick={irPaginaPosterior}>
                                <i className="fa-solid fa-forward-step" aria-hidden="true"></i>
                            </button>
                            
                            <button type="button" className="br-button secondary circle mt-3 mt-sm-0 ml-sm-3" disabled={paginaAtual >= totalPaginas} onClick={irUltimaPagina}>
                                <i className="fa-solid fa-forward-fast" aria-hidden="true"></i>
                            </button>
                        </Fragment>
                    }

                    {! ocultaBotaoBaixar &&                                         
                        <button className="br-button primary mt-3 mt-sm-0 ml-2" type="button" aria-label="Ícone ilustrativo" disabled={! props.arquivo} onClick={() => {download(props.arquivo)}}>
                            <i className="fa-solid fa-download mr-1" aria-hidden="true"></i>
                            Baixar
                        </button>
                    }
                
                    {! ocultaBotaoImprimir &&                                         
                        <button className="br-button secondary mt-3 mt-sm-0 ml-2" type="button" disabled={! props.arquivo} onClick={() => {print(props.arquivo)}}>
                            <i className="fa-solid fa-print mr-1" aria-hidden="true"></i>
                            Imprimir
                        </button>                
                    }
                    
                </div>
            </div>
            <br/>
            <Document file={props.arquivo} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={paginaAtual} />
            </Document>
        </Fragment>
    )
    
}