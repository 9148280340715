import React from "react";
import ItemServico from "./ItemServico";
import { Container } from "./styles.js";
import { MensagemNaoHaItensHistorico } from "../MensagemNaoHaItensHistorico";

function ListaServicos({ servicos, prefixo }) {
  return (
    <Container>
      {servicos && servicos.length > 0 ? (
        servicos.map((servico, index) => (
          <ItemServico
            key={`${prefixo}-${servico.protocolo}-${index}`}
            servico={servico}
          />
        ))
      ) : (
        <MensagemNaoHaItensHistorico />
      )}
    </Container>
  );
}

export default ListaServicos;
