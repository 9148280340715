import React from "react";

function Quantidade({ quantidade }) {
  if (!quantidade) return <></>;
  return <strong>({quantidade})</strong>;
}
function LabelTab({ label, quantidade, ...props }) {
  return (
    <button type="button" {...props}>
      <span>
        {label} <Quantidade quantidade={quantidade} />
      </span>
    </button>
  );
}

export default LabelTab;
