import styled from 'styled-components';

export const DivBanner = styled.div `
    background-image: url(${props => props.imagem});
    background-repeat: no-repeat;
    background-size: 100% 100% ;
    object-fit: scale-down;
    padding: 0% 0% 9% 2%;
    align-self: baseline;  
    min-height: 4em;
    width: 100%;   
    
    @media (min-width: 450px) {      
        height: 5em;       
      } 
}
`;




