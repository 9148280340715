import { Component } from 'react';

export class RedirectPage extends Component {
  render() {
    return null;
  }

  componentDidMount(){
    window.location.replace(this.props.to);
  }
}
