import React from "react";

function Quadrado({ height, width }) {
  return (
    <span
      className="skeleton square d-block"
      style={{
        width,
        height,
      }}
    ></span>
  );
}

export default Quadrado;
