import styled from "styled-components";

export const ContainerRadio = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-scale-3x);

  .br-radio + .br-radio {
    margin-top: 0;
  }

  @media (max-width: 575px) {
    align-items: center;
    justify-content: center;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 575px) {
    align-items: center;
    justify-content: center;
  }

  .br-select {
    width: 100%;
  }
`;
