import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 360px;
  margin: 24px auto 0 auto;
  align-items: center;

  > img {
    max-height: 128px;
    max-width: 128px;
    flex-shrink: 1;
  }

  > div {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;

    h5 {
      text-transform: none;
      font-size: var(--font-size-scale-up-02);
      margin-bottom: var(--spacing-scale-half);
    }

    p {
      color: var(--gray-cool-50);
    }
  }

  @media (min-width: 575px) {
    flex-direction: row;
    gap: 16px;
    align-items: center;
    max-width: 575px;
  }

  @media (min-width: 609px) {
    > div h5 {
      font-size: var(--font-size-scale-up-03);
    }
  }
`;
