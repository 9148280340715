import React from "react";

export const BRRadio = React.forwardRef(
  ({ id, label, onClick, value, ...props }, ref) => {
    return (
      <div className="br-radio" ref={ref}>
        <input id={id} type="radio" name="radio" value={value} {...props} />
        <label htmlFor={id}>{label}</label>
      </div>
    );
  }
);
