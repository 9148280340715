import styled from 'styled-components';

export const ContainerLegenda = styled.div `
    padding-top: 15px;
    padding-bottom: 10px;
`;

export const TextoLegenda = styled.span `
    color: #636363;
    font-weight: 300;
    font-size: 1.5rem;
    
`;




