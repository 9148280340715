import styled from "styled-components";

export const Container = styled.button`
  padding: 0;
  margin-left: auto;
  display: block;
  color: var(--gray-30);

  > i {
    font-size: var(--font-size-scale-up-02);
  }
`;
