import { ProviderHistoricoServicos } from "../../../Contexts/ProviderHistoricoServicos";
import { ProviderFiltrosAvancados } from "../../../hooks/ContextoFiltrosAvancados";
import { ScrollPageProvider } from "../../../hooks/useRolarPagina";

export function ProvidersPaginaHistoricoDeServicos({ children }) {
  return (
    <ProviderHistoricoServicos>
      <ProviderFiltrosAvancados>
        <ScrollPageProvider>{children}</ScrollPageProvider>
      </ProviderFiltrosAvancados>
    </ProviderHistoricoServicos>
  );
}
