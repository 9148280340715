import React, {Fragment} from 'react';
import Modal from '../Modal';

export default function ModalTelaCustomizada (props) {
    
    return (
        <Fragment>
            <Modal visivel={props.visivel}>
                <div className="container-fluid" >
                    <div className="br-modal-header">
                        <button className="br-button close circle small" type="button" data-dismiss="br-modal" aria-label="Close" onClick={props.onClose}><i className="fas fa-times" aria-hidden="true"></i></button>
                    </div>
                    <div className="br-modal-body" >
                        {props.children}
                    </div>
                </div>
            </Modal>
        </Fragment>
    )
}