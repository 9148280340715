import { DateTime } from "luxon";

export function stringDataParaData(stringData) {
  const formato = "dd/MM/yyyy";
  const data = DateTime.fromFormat(stringData, formato);
  return data.isValid ? data.toJSDate() : null;
}

export function isoStringParaData(isoString) {
  return DateTime.fromISO(isoString).toJSDate();
}
