import styled from "styled-components";

export const Container = styled.div`
  margin-top: var(--spacing-scale-4x);

  .tab-nav ul {
    scrollbar-width: none; /* Firefox */
  }

  .tab-nav ul::-webkit-scrollbar {
    display: none;
  }
  .tab-nav ul::-webkit-scrollbar-track {
    background: none;
  }
  .tab-nav ul::-webkit-scrollbar-thumb {
    background: none;
  }
  .tab-nav ul:hover::-webkit-scrollbar-thumb {
    background: none;
  }

  > .tab-nav {
    position: relative;

    display: flex;
    scroll-behavior: smooth;
    overflow: hidden;
  }

  > .tab-nav ul {
    overflow-x: auto;
  }

  > .tab-nav ul li button,
  .tab-nav ul li button span,
  .tab-nav ul li button span strong {
    font-weight: var(--font-weight-semi-bold);
    color: var(--gray-80);
  }

  .tab-nav ul li.active button span strong {
    //cor da quantidade de itens no label da aba
    color: var(--interactive);
  }

  .tab-item.active button,
  .tab-item.is-active button {
    //cor da borda inferior da aba ativa
    border-color: var(--interactive);
  }

  .tab-item button {
    font-size: calc(var(--tab-font-size) - 2pt);
  }

  .tab-nav i {
    color: var(--interactive);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
  }

  > .tab-nav i {
    height: 28px;
    width: 28px;
    align-items: center;
    justify-content: center;
    display: flex;
    background: radial-gradient(
      circle,
      hsla(0, 0%, 100%, 1) 0%,
      hsla(0, 0%, 100%, 0.9) 85%,
      hsla(0, 0%, 100%, 0.8) 100%
    );
  }

  > .tab-nav i.fa-chevron-left {
    left: 0;
    padding-left: 4px;
  }

  > .tab-nav i.fa-chevron-right {
    right: 0;
    padding-right: 4px;
  }

  // Desabilitar hover das abas
  .tab-item a:not(:disabled):hover,
  .tab-item button:not(:disabled):hover {
    background-image: initial;
  }
`;

export const ContainerPaginacao = styled.section`
  display: flex;
  justify-content: center;
  padding: var(--spacing-scale-4x) 0;
`;
