import styled from 'styled-components';


export const Container = styled.div `
    cursor: ${props => props.clicavel === true ? 'pointer' : 'default'};
`;

export const TextoNivelConta = styled.span `
    color: #636363;
    font-size: 1rem;
    font-weight: 400;
`;

export const ImagemNivelConta = styled.img `
    width: 100%!important;
    max-width: none!important;
    float: none!important;
    margin: 1em 0 0!important;
    padding-bottom: 15px;
    align: justify;
}`;



