import styled from 'styled-components';

export const DivCard = styled.div `
    text-align: center;
    min-height: 200px;
    padding: var(--spacing-scale-2x);
    background: #f3f3f3 0 0 no-repeat padding-box !important;
    box-shadow: 0 2px 4px rgb(0 0 0 / 20%) !important;
    cursor: pointer;
    height: 210px;
    position: relative;
`;

export const DivTag = styled.div `
    position:absolute;
    width: 100%;
`;

export const TextoTitulo = styled.div `
    text-align: ${props => props.alinhamento ? props.alinhamento : 'left'};
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
    color: #0c326f;
`;

export const TextoConteudo = styled.p `
    font-size: 11.67px;
    font-weight: bold;
    margin: 0;
    font-weight: 400;
    color: #0B326F;
    border: 10px;
   
`;






